import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  type CurrencyCodes,
  EarlyAccessFeature,
  Metadata,
  Positions,
  Renderer,
  type ReportFilter,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { analyticsAlertText, globalText } from "../../../../assets/texts";
import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import { useAnalyticsDimensions } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions/useAnalyticsDimensions";
import { useCloudAnalyticsMetadata } from "../../../../Components/hooks/cloudAnalytics/useCloudAnalyticsMetadata";
import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import { InfoTooltip } from "../../../../Components/InfoTooltip/InfoTooltip";
import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import PreviewReport, { type PreviewData } from "../../../../Pages/CloudAnalytics/previewReport/PreviewReport";
import { type BudgetInfo, type MetadataOption } from "../../../../types";
import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";
import { colsMap } from "../../generateReport/utils";
import { getDefaultForecastSettings } from "../../report/config/ForecastSettings/utils";
import { QueryType } from "../../report/ReportQuery";
import { convertStringToDataRecord, extractCloudProviders } from "../../report/ReportQueryUtils";
import { type CurrentLastPeriods } from "../../ReportData";
import {
  budgetConfigurationDynamicOptions,
  BudgetConfigurationOptions,
  BudgetConfigurations,
  BudgetDynamicConfigurations,
  BudgetTypes,
  CurrencyOptions,
  getCurrencyOption,
  timeIntervalOptions,
} from "../../utilities";
import { getAlertAmountFromPercentage } from "../shared";
import { getFixedTimeInterval, getRolloverLimit, getTimeRangeOption } from "../utils";
import BudgetInfoPanel from "./BudgetInfoPanel";
import DaysNextPeriodPullOver from "./DaysNextPeriodPullOver";
import MonthsNextPeriodPullOver from "./MonthsNextPeriodPullOver";
import QuartersNextPeriodPullOver from "./QuartersNextPeriodPullOver";
import YearsNextPeriodPullOver from "./YearsNextPeriodPullOver";

type Props = {
  setOverrideStep: (step: number) => void;
  dimensionOptions: MetadataOption[];
  budgetInfo: BudgetInfo;
  setBudgetInfo: Dispatch<SetStateAction<BudgetInfo>>;
  isCurrentUserEditor: boolean;
  currentLastPeriods: CurrentLastPeriods;
  setCurrentLastPeriods: Dispatch<SetStateAction<CurrentLastPeriods>>;
  setThresholdsAmount: Dispatch<SetStateAction<number>>;
};

const AMORTIZED_COST_METRIC = 5;
const AMORTIZED_COST_EXTENDED_METRIC = "amortized_cost";

const Step2 = ({
  setOverrideStep,
  budgetInfo,
  setBudgetInfo,
  isCurrentUserEditor,
  currentLastPeriods,
  setCurrentLastPeriods,
  setThresholdsAmount,
}: Props) => {
  const { attributions } = useAttributionsContext();
  const { metadata: metadataSnapshots } = useCloudAnalyticsMetadata();
  const [renderer, setRenderer] = useState(Renderer.STACKED_COLUMN_CHART);

  const [lastPeriodCostLoading, setLastPeriodCostLoading] = useState<boolean>(false);
  const [refreshPreview, setRefreshPreview] = useState<boolean>(false);
  const { dimensions } = useAnalyticsDimensions({
    metadataSnapshots,
    attributions,
  });
  const showBudgetSeasonality = useFeatureFlag(EarlyAccessFeature.BUDGET_SEASONALITY);

  const handleAmortizedCostChange = useCallback(
    (event) => {
      setBudgetInfo((prev) => ({
        ...prev,
        amortizedCost: event.target.checked,
      }));
    },
    [setBudgetInfo]
  );

  const handleChangeAmount = useCallback(
    (event) => {
      const newAmount = parseFloat(event.target.value);
      setBudgetInfo((prev) => ({ ...prev, budgetAmount: isNaN(newAmount) ? 0 : newAmount }));
    },
    [setBudgetInfo]
  );

  const handleBudgetConfigurationOptionChanged = useCallback(
    (event) => {
      setBudgetInfo((prev) => {
        const temp = { ...prev };
        const type = event.target.value;
        temp.configurationOption = {
          type,
          dynamic: type === BudgetConfigurations.DYNAMIC ? BudgetDynamicConfigurations.LAST_PERIOD : undefined,
        };
        return temp;
      });
    },
    [setBudgetInfo]
  );

  const previewData = useMemo(() => {
    if (!dimensions) {
      return;
    }
    const filters =
      budgetInfo.filters?.flatMap((f) => {
        const md = dimensions?.find((d) => f.id === d.id);

        const filter: ReportFilter = {
          ...f,
          type: (f.type || md?.data.type) ?? "",
          position: md?._position,
          id: f.id,
          field: (f.field || md?.data.field) ?? "",
          key: (f.key || md?.data.key) ?? "",
          includeInFilter: true,
          limit: 0,
          limitMetric: 0,
          composite: [],
          regexp: md?._regexp ?? null,
          values: f.values ?? [],
        };
        return filter;
      }) ?? [];

    const cloudProviders = extractCloudProviders(dimensions);

    const timeIntervalOption = timeIntervalOptions.find((t) => t.value === budgetInfo.currentTypeAndFrequency.period);

    dimensions?.forEach((d) => {
      if (filters.some((f) => f.id === d.id)) {
        d._position = Positions.ROW;
      }

      if (d._position === Positions.COL) {
        if (d.data.key === timeIntervalOption?.value || d.data.key === "year") {
          d._visible = true;
        }
      }
    });

    const rows = convertStringToDataRecord(
      dimensions,
      filters.map((f) => f.id)
    );
    const cols = convertStringToDataRecord(dimensions, colsMap[budgetInfo.currentTypeAndFrequency.period]);
    const attribution = filters.find((f) => f.id === `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`);
    const attributionIds = attribution ? attribution.values : [];
    const initialScope = budgetInfo.scope.map((f) => f.ref.id);
    const timeRange = {
      mode:
        budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED ? TimeSettingsMode.Fixed : TimeSettingsMode.Last,
      time: budgetInfo.currentTypeAndFrequency.period,
      range:
        budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED
          ? { start: budgetInfo.startPeriod, end: budgetInfo.endPeriod }
          : undefined,
      amount: 5,
    };

    const previewData: PreviewData = {
      currency: budgetInfo.currency,
      scope: initialScope.length > 0 ? initialScope : (attributionIds ?? []),
      attributionGroups: [],
      timeInterval:
        budgetInfo.currentTypeAndFrequency.type === BudgetTypes.RECURRING
          ? budgetInfo.currentTypeAndFrequency.period
          : getFixedTimeInterval(budgetInfo.startPeriod, budgetInfo.endPeriod),
      timeRangeOptions: getTimeRangeOption(
        budgetInfo.currentTypeAndFrequency.type,
        budgetInfo.currentTypeAndFrequency.period,
        budgetInfo.startPeriod,
        budgetInfo.endPeriod
      ),
      attributionGroupsPayload: [],
      rows,
      filters,
      cols,
      cloudProviders,
      dataSource: budgetInfo.dataSource,
      forecastSettings: getDefaultForecastSettings(timeRange, budgetInfo.currentTypeAndFrequency.period, "grouping"),
      type: QueryType.BUDGET,
    };
    if (budgetInfo.amortizedCost) {
      previewData.extendedMetric = AMORTIZED_COST_EXTENDED_METRIC;
      previewData.metric = AMORTIZED_COST_METRIC;
    }
    return previewData;
  }, [
    dimensions,
    budgetInfo.filters,
    budgetInfo.currentTypeAndFrequency.period,
    budgetInfo.currentTypeAndFrequency.type,
    budgetInfo.scope,
    budgetInfo.currency,
    budgetInfo.startPeriod,
    budgetInfo.endPeriod,
    budgetInfo.dataSource,
    budgetInfo.amortizedCost,
  ]);
  const isInit = useRef(true);

  useEffect(() => {
    if (previewData) {
      if (!isInit.current) {
        setRefreshPreview(true);
      } else {
        isInit.current = false;
      }
    }
  }, [previewData]);

  const NextPeriodPullOverInputs = () => {
    switch (budgetInfo.currentTypeAndFrequency.period) {
      case TimeInterval.DAY:
        return <DaysNextPeriodPullOver currency={budgetInfo.currency} disabled={!isCurrentUserEditor} />;
      case TimeInterval.MONTH:
        return (
          <MonthsNextPeriodPullOver
            startDate={budgetInfo.startPeriod}
            currency={budgetInfo.currency}
            disabled={!isCurrentUserEditor}
          />
        );
      case TimeInterval.QUARTER:
        return <QuartersNextPeriodPullOver currency={budgetInfo.currency} disabled={!isCurrentUserEditor} />;
      case TimeInterval.YEAR:
        return (
          <YearsNextPeriodPullOver
            startDate={budgetInfo.startPeriod}
            currency={budgetInfo.currency}
            disabled={!isCurrentUserEditor}
          />
        );
      default:
        return <></>;
    }
  };

  const updateAlerts = useCallback(() => {
    let amount = budgetInfo.budgetAmount;
    if (currentLastPeriods?.lastPeriod) {
      if (budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD) {
        amount = Math.round((currentLastPeriods.lastPeriod ?? 0) * 100) / 100;
      } else if (
        budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH
      ) {
        amount =
          (Math.round((currentLastPeriods.lastPeriod ?? 0) * 100) / 100) * (budgetInfo.growthPerPeriod / 100 + 1);
      }
    }

    if (budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH) {
      amount = budgetInfo.budgetAmount * (budgetInfo.growthPerPeriod / 100 + 1);
    }

    setThresholdsAmount(amount);
    setBudgetInfo((prev) => ({
      ...prev,
      alerts: prev.alerts.map((a) => ({
        ...a,
        amount: getAlertAmountFromPercentage(a.percentage, amount),
      })),
    }));
  }, [
    budgetInfo.budgetAmount,
    budgetInfo.configurationOption.dynamic,
    budgetInfo.growthPerPeriod,
    currentLastPeriods?.lastPeriod,
    setBudgetInfo,
    setThresholdsAmount,
  ]);

  useEffect(() => {
    updateAlerts();
  }, [
    budgetInfo.budgetAmount,
    budgetInfo.configurationOption,
    budgetInfo.growthPerPeriod,
    currentLastPeriods?.lastPeriod,
    updateAlerts,
  ]);

  const [growthInputEmpty, setGrowthInputEmpty] = useState(false);

  const onGrowthInputChanged = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setGrowthInputEmpty(true);
      return;
    }
    setGrowthInputEmpty(false);
    if (inputValue === "100") {
      setBudgetInfo((prev) => ({
        ...prev,
        growthPerPeriod: 100,
      }));
      return;
    }
    const limitedValue = inputValue.slice(0, 2);
    const val = parseInt(limitedValue, 10);
    if (!isNaN(val)) {
      setBudgetInfo((prev) => ({
        ...prev,
        growthPerPeriod: val,
      }));
    }
  };

  const getCurrentLastPeriods = useCallback(
    (periods) => {
      if (!periods) {
        return;
      }
      if (
        periods.lastPeriod !== currentLastPeriods?.lastPeriod ||
        periods.currentPeriod !== currentLastPeriods?.currentPeriod
      ) {
        setCurrentLastPeriods(periods);
      }
    },
    [currentLastPeriods?.currentPeriod, currentLastPeriods?.lastPeriod, setCurrentLastPeriods]
  );

  const onBudgetConfigurationDynamicChanged = useCallback(
    (e) => {
      const value = e.target.value as BudgetDynamicConfigurations;
      setBudgetInfo((prev) => ({
        ...prev,
        configurationOption: {
          ...prev.configurationOption,
          dynamic: value,
        },
        rolloverLimit: value === BudgetDynamicConfigurations.NEXT_PERIOD_ROLLOVER ? 1 : 0,
      }));
    },
    [setBudgetInfo]
  );

  useEffect(() => {
    const maxRolloverLimit = getRolloverLimit(budgetInfo.currentTypeAndFrequency.period);
    if ((budgetInfo.rolloverLimit ?? 0) > maxRolloverLimit) {
      setBudgetInfo((prev) => ({
        ...prev,
        rolloverLimit: maxRolloverLimit,
      }));
    }
  }, [budgetInfo.currentTypeAndFrequency.period, budgetInfo.rolloverLimit, setBudgetInfo]);

  const getBudgetAmountDisplayValue = useCallback(() => {
    const isLastPeriodBased =
      budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD ||
      budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH;

    if (isLastPeriodBased) {
      return currentLastPeriods?.lastPeriod ? Math.round(currentLastPeriods.lastPeriod * 100) / 100 : "";
    }

    return Math.round(budgetInfo.budgetAmount) || "";
  }, [budgetInfo.configurationOption.dynamic, budgetInfo.budgetAmount, currentLastPeriods?.lastPeriod]);

  return (
    <Stack
      sx={{
        gap: 3,
        mt: 4,
      }}
    >
      <BudgetInfoPanel
        typeAndFrequency={budgetInfo.currentTypeAndFrequency.label}
        startDate={budgetInfo.startPeriod.toFormat("dd LLL, yyyy")}
        endDate={
          budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED
            ? budgetInfo.endPeriod?.toFormat("dd LLL, yyyy")
            : undefined
        }
        setOverrideStep={setOverrideStep}
        filters={budgetInfo.filters}
        dimensionOptions={dimensions ?? []}
        scope={budgetInfo.scope}
      />
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            mb: 2,
          }}
        >
          {budgetTxt.CREATE_BUDGET.BUDGET_AMOUNT}
        </Typography>

        <Autocomplete
          sx={{
            mb: 3,
          }}
          disabled={!isCurrentUserEditor}
          onChange={(_, val) => {
            if (val) {
              setBudgetInfo((prev) => ({ ...prev, currency: val.split(" ")[0] as CurrencyCodes }));
            }
          }}
          options={CurrencyOptions.map((c) => getCurrencyOption(c))}
          value={getCurrencyOption(budgetInfo.currency)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={analyticsAlertText.STEPPER.CURRENCY}
              placeholder={analyticsAlertText.STEPPER.SELECT_CURRENCY}
              fullWidth
              size="small"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          )}
        />

        <TextField
          required
          variant="outlined"
          type="number"
          label={budgetTxt.CREATE_BUDGET.BUDGET_AMOUNT}
          fullWidth
          size="small"
          value={getBudgetAmountDisplayValue()}
          onChange={handleChangeAmount}
          onKeyUp={onKeyPressPreventNonNumeric}
          disabled={
            !isCurrentUserEditor ||
            budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD ||
            budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH
          }
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">{getCurrencySymbol(budgetInfo.currency)}</InputAdornment>
              ),
            },

            htmlInput: { min: 0 },

            inputLabel: {
              shrink: true,
            },
          }}
        />
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary",
            mt: 1,
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          {budgetTxt.CREATE_BUDGET.LAST_PERIOD_ACTUAL_COST}:
          {lastPeriodCostLoading ? (
            <CircularProgress size={12} thickness={5} color="inherit" />
          ) : (
            <b>
              {currentLastPeriods?.lastPeriod
                ? `${getCurrencySymbol(budgetInfo.currency)}${(Math.round(currentLastPeriods.lastPeriod * 100) / 100).toLocaleString()}`
                : globalText.NA}
            </b>
          )}
        </Typography>
      </Box>
      {budgetInfo.currentTypeAndFrequency.type !== BudgetTypes.FIXED && (
        <>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                mb: 1,
              }}
            >
              {budgetTxt.CREATE_BUDGET.BUDGET_CONFIGURATION}
            </Typography>

            <RadioGroup
              sx={{
                pl: 1,
              }}
              value={budgetInfo.configurationOption.type}
              onChange={handleBudgetConfigurationOptionChanged}
            >
              {BudgetConfigurationOptions.filter(
                (option) => option.value !== BudgetConfigurations.SPECIFY || showBudgetSeasonality
              ).map((option) => (
                <Box key={option.value}>
                  <Tooltip
                    title={
                      option.value === BudgetConfigurations.SPECIFY &&
                      budgetInfo.currentTypeAndFrequency.period === TimeInterval.WEEK
                        ? budgetTxt.CREATE_BUDGET.WEEKLY_BUDGET_SEASONALITY_TOOLTIP
                        : ""
                    }
                    placement="bottom-start"
                  >
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      disabled={
                        !isCurrentUserEditor ||
                        (option.value === BudgetConfigurations.SPECIFY &&
                          budgetInfo.currentTypeAndFrequency.period === TimeInterval.WEEK)
                      }
                    />
                  </Tooltip>
                  {option.value === BudgetConfigurations.DYNAMIC &&
                    budgetInfo.configurationOption.type === BudgetConfigurations.DYNAMIC && (
                      <Box
                        sx={{
                          maxWidth: "552px",
                        }}
                      >
                        <Select
                          fullWidth
                          value={budgetInfo.configurationOption.dynamic}
                          onChange={onBudgetConfigurationDynamicChanged}
                          size="small"
                        >
                          {budgetConfigurationDynamicOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              disabled={
                                (option.value === BudgetDynamicConfigurations.PERCENTAGE_GROWTH &&
                                  !budgetInfo.budgetAmount) ||
                                (option.value === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH &&
                                  !budgetInfo.budgetAmount)
                              }
                              sx={{
                                maxWidth: 552,
                                whiteSpace: "break-spaces",
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {(budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH ||
                          budgetInfo.configurationOption.dynamic ===
                            BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH) && (
                          <TextField
                            value={
                              growthInputEmpty || budgetInfo.growthPerPeriod === 0 ? "" : budgetInfo.growthPerPeriod
                            }
                            onChange={onGrowthInputChanged}
                            onBlur={() => {
                              if (growthInputEmpty) {
                                setGrowthInputEmpty(false);
                                setBudgetInfo((prev) => ({ ...prev, growthPerPeriod: 1 }));
                                return;
                              }
                              if (budgetInfo.growthPerPeriod < 1) {
                                setBudgetInfo((prev) => ({ ...prev, growthPerPeriod: 1 }));
                              } else if (budgetInfo.growthPerPeriod > 100) {
                                setBudgetInfo((prev) => ({ ...prev, growthPerPeriod: 100 }));
                              }
                            }}
                            sx={{ mt: 2 }}
                            fullWidth
                            required
                            label={budgetTxt.CREATE_BUDGET.GROWTH}
                            disabled={!isCurrentUserEditor || !budgetInfo.budgetAmount}
                            type="text"
                            onKeyUp={onKeyPressPreventNonNumeric}
                            slotProps={{
                              input: {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              },
                              htmlInput: { min: 1 },
                            }}
                          />
                        )}

                        {budgetInfo.configurationOption.dynamic ===
                          BudgetDynamicConfigurations.NEXT_PERIOD_ROLLOVER && (
                          <TextField
                            required
                            sx={{
                              mt: 2,
                            }}
                            variant="outlined"
                            type="number"
                            fullWidth
                            size="small"
                            slotProps={{
                              input: {
                                inputProps: {
                                  min: 1,
                                  max: getRolloverLimit(budgetInfo.currentTypeAndFrequency.period),
                                },
                              },
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                            label={budgetTxt.CREATE_BUDGET.ROLLOVER_MONTHS_TITLE}
                            value={budgetInfo.rolloverLimit ?? ""}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setBudgetInfo((prev) => {
                                if (inputValue === "") {
                                  return { ...prev, rolloverLimit: undefined };
                                }
                                const val = parseInt(inputValue, 10);
                                const max = getRolloverLimit(prev.currentTypeAndFrequency.period);
                                return { ...prev, rolloverLimit: isNaN(val) ? undefined : Math.min(val, max) };
                              });
                            }}
                            onKeyUp={onKeyPressPreventNonNumeric}
                            disabled={!isCurrentUserEditor}
                          />
                        )}
                      </Box>
                    )}
                </Box>
              ))}
            </RadioGroup>
          </Box>

          {budgetInfo.configurationOption.type === BudgetConfigurations.SPECIFY && <NextPeriodPullOverInputs />}
        </>
      )}
      <Box>
        <Typography variant="subtitle1" fontWeight={500} mb={1}>
          {budgetTxt.CREATE_BUDGET.ADVANCED_SETTINGS}
        </Typography>
        <FormGroup
          sx={{
            pl: 1,
          }}
        >
          <InfoTooltip description={budgetTxt.CREATE_BUDGET.AMORTIZED_COST_TOOLTIP} placement="right">
            <FormControlLabel
              sx={{
                marginRight: 0,
              }}
              control={<Checkbox checked={budgetInfo.amortizedCost} onChange={handleAmortizedCostChange} />}
              label={budgetTxt.CREATE_BUDGET.USE_AMORTIZED_COST}
            />
          </InfoTooltip>
        </FormGroup>
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            mb: 1,
          }}
        >
          {budgetTxt.CREATE_BUDGET.BUDGET_PREVIEW}
        </Typography>
      </Box>
      {previewData && (
        <PreviewReport
          attributions={attributions}
          displayForecast
          previewData={previewData}
          runQuery={refreshPreview}
          renderer={renderer}
          setRenderer={setRenderer}
          setRunQuery={(reset) => {
            setRefreshPreview(reset ?? false);
          }}
          getCurrentLastPeriods={getCurrentLastPeriods}
          getQueryRunningStatus={(queryRunning) => {
            setLastPeriodCostLoading(queryRunning);
          }}
        />
      )}
    </Stack>
  );
};

export default Step2;
