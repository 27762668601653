import { Box, Button, MenuItem, Select, Stack, Typography } from "@mui/material";

const Fsk8sHeader = () => (
  <Box justifyContent="space-between" display="flex" alignItems="center">
    <Typography variant="h1">Flexsave for Kubernetes</Typography>
    <Stack direction="row" spacing={1}>
      <Button variant="outlined">Manage clusters</Button>
      <Select size="small" value="7d">
        <MenuItem value="7d">Last 7 days</MenuItem>
      </Select>
    </Stack>
  </Box>
);

export default Fsk8sHeader;
