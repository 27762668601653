export const allocationTxt = {
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "Name",
    TYPE: "Allocation type",
    OWNER: "Owner",
    SUBSCRIBED: "Subscribed",
  },
  FILTER_ALLOCATIONS: "Filter allocations",
  ALLOCATION_PRESET_NOT_DELETE: "Preset allocations are non-deletable",
  ALLOCATION_USED_BY_BUDGET: "Cannot delete allocation used by the budget",
  ALLOCATION_USED_BY_DAILY_DIGEST: "Cannot delete allocation used by daily digest",
  ALLOCATION_USED_BY_METRIC: "Cannot delete allocation used by the metric",
  ALLOCATION_USED_BY_ALLOCATION_GROUP: "Cannot delete allocation used by the allocation group",
  ALLOCATION_USED_BY_ALERT: "Cannot delete allocation used by the alert",
  ALLOCATION_USED_BY_ORG: "Cannot delete allocation used by the organization",
  ALLOCATION_USED_BY_REPORT: "Cannot delete allocation used by the report",
  ALLOCATION_NAME: "Allocation name",
  CANNOT_DELETE: "Only allocation owners can delete allocations",
  CREATE_NEW_ALLOCATION: "Create allocation",
  CREATE_NEW_ALLOCATION_STEP: (step, totalSteps) => `Create new allocation (${step}/${totalSteps})`,
  COPY_OF: "Copy of",
  DELETE: "Delete",
  DEFINE_LOGIC_TITLE: "Define allocation rule logic",
  DEFINE_LOGIC: "Define allocation rule logic",
  ANOMALY_DETECTION: "Anomaly detection",
  DELETE_ALC_ERROR: "Failed to delete allocation(s)",
  DELETE_ALC_SUCCESS: "Allocation(s) successfully deleted",
  DELETE_MESSAGE: "Please note: the allocations you selected will be permanently deleted.",
  DELETE_SELECTED: "Delete Selected Allocation(s)",
  DIALOG_ACTION_ADD: "Add",
  DIALOG_ACTION_BACK: "Back",
  DIALOG_ACTION_CANCEL: "Cancel",
  DIALOG_ACTION_NEXT: "Next",
  DIALOG_SUB_TITLE: "Create basic allocations. Advanced options are available in Analytics > Allocations",
  DIMENSION_OPTIONS: "Dimension options",
  DIMENSION_TYPE: "Dimension type",
  DUPLICATE: "Duplicate allocation",
  EDIT: "Edit settings",
  FOLLOWING_CONDITIONS: "of the following conditions:",
  IS: "is ",
  IS_NOT: "is not ",
  LEARN_MORE_ALERT:
    "Allocations are a flexible way to group resources and their associated costs, helping you understand the cloud costs and relate them to the business in your Reports",
  MEETS: "Meets",
  NEW_ALLOCATION: "New allocation",
  NO_ALLOCATION_NAME: "Please enter an allocation name",
  NON_EDITOR_ALERT:
    "You are not an editor for this allocation - please contact the allocation owner to be added as a collaborator.",
  NON_EDITOR_ALERT_BUTTON_TEXT: "Open docs",
  REGEX_MATCH: "matches regular expression ",
  REGEX_NO_MATCH: "does not match regular expression ",
  SHARE: "Share allocation",
  SHARE_FAILED: "Share allocation failed",
  SHARE_SUCCESS: "Permissions updated successfully",
  SUBSCRIBE_TO_DAILY_DIGEST: "Subscribe to daily digest",
  SUCCESSFULLY_CREATED: "Successfully created allocation",
  SUCCESSFULLY_UPDATED: "Allocation saved successfully",
  SUCCESSFULLY_SUBSCRIBED: "Successfully subscribed to daily digest",
  SUCCESSFULLY_UNSUBSCRIBED: "Successfully unsubscribed from daily digest",
  UNSUBSCRIBE_FROM_DAILY_DIGEST: "Unsubscribe from daily digest",
  UNTITLED_ALLOCATION: "Untitled allocation",
  SCOPE: "Scope",
  SELECT_ALLOCATION: "Select Allocation",
  CHANGES_SAVED: "All changes are saved",
  INVALID_FORMULA: "Formula entered is invalid",
  SAVE_AS_TITLE: "Save new allocation as",
  SAVE_AS_HELPER_TEXT: "This will create a new allocation with you as owner",
  PREVIEW: "Allocation Preview",
  SHARE_ALLOCATION: "Share allocation",
  SHARE_ALLOCATIONS: "Share allocations",
  ALLOCATION_TITLE: "Allocations",
  ALLOCATION_MOBILE_INFO: "You can’t edit or Add allocation rule rules on mobile. Try viewing on your desktop browser.",
  ALLOCATION_DESCRIPTION:
    "Set up an allocation rule to group cloud resources based on your business aspects, for example, by teams, environments, or product lines.",
  ALLOCATION_SAVE: "Save",
  ALLOCATION_SAVE_AS: "Save As",
  ALLOCATION_DUPLICATE: "Duplicate",
  ALLOCATION_SAVE_TITLE: "Save and name your allocation group",
  ALLOCATION_DUPLICATE_SAVE_TITLE: "Save and name your allocation",
  ALLOCATION_NAME_LABEL: "Allocation name",
  ALLOCATION_RULE_NAME_LABEL: "Allocation rule name",
  ALLOCATION_DUPLICATE_SAVE_DESCRIPTION:
    "You are duplicating an existing allocation. To visualize and segment this shared data across the console, please name it first.",
  ALLOCATION_SAVE_DESCRIPTION:
    "You are creating an allocation group made up of multiple rules. To visualize and segment this shared data across the console, please name it first.",
  ALLOCATION_CANCEL: "Cancel",
  UNALLOCATED_COSTS: "Unallocated",
  UNALLOCATED_COSTS_DESC:
    'When billing items do not match any of the allocations, they will fall under the "unallocated" bucket. For more information, check out our documentation on ',
  COST_ALLOCATION: "cost allocation",
  UNALLOCATED_COSTS_HELPER_TEXT:
    "Define how you would like unallocated rule to be labeled. i.e. unallocated, unattributed, misc",
  ADD_ALLOCATION: "Add allocation rule",
  ADD_EXISTING_ALLOCATION: "Select existing allocation rule",
  ADD_UNALLOCATED_COSTS: "Add unallocated rule",
  ALLOCATION_SEGMENT_TITLE: "Start segmenting shared data",
  ALLOCATION_SEGMENT_DESCRIPTION:
    "Adding multiple allocations will allow you to segment data among multiple resource groups (defined by the rules you create) and output this set of allocations into a group. An example use case would be dividing up cost and usage data among different teams or environments.",
  UNALLOCATED_MODAL_TITLE: "Confirm adding unallocated rule?",
  UNALLOCATED_MODAL_DESCRIPTION:
    "Adding unallocated rule to this single rule will convert this allocation into a group.",

  USE_EXISTING_ALLOCATION: "Use existing rule",
  ADD_DIMENSION: "Add dimension",
  FAILED_TO_SAVE: "Failed to save allocation",
  LEARN_HOW_TO_DEFINE_LOGIC_TITLE: "Learn how to define allocation logic",
  NAME_YOUR_ALLOCATION: "Name your allocation",
  ALLOCATION_EDIT_SAVE_WARNING:
    "You have made updates to existing allocations, which may impact budgets, reports, cost anomalies etc. The allocations that have been updated are:",
  ALLOCATION_EDIT_WARNING:
    "You are updating an existing rule which may impact existing budgets, cost anomalies etc. These changes will come into effect upon saving.",
  SINGLE_ALLOCATION_EDIT_WARNING:
    "You are adding a rule or more to an allocation that is used in other allocation groups. You'll have to save it as a new allocation group and changes won't effect the existing following allocation groups",
  ALLOCATION_SAVE_A_COPY: "Save a copy of this allocation",
  ALLOCATION_SAVE_A_COPY_DESCRIPTION: "In order to successfully save this allocation, you must name it first.",
  ALLOCATION_RULE_LABEL: "Allocation rule",
  ALLOCATION_COPY: "Make a copy",
  ALLOCATION_RULE_INFO: "Only owner or editors can edit this allocation",
  ALLOCATION_RULE_TOOLTIP:
    "Shared costs always go to the allocation higher in the list. Reorder your rules accordingly.",
  ALLOCATION_EDIT_FILTER:
    "You are updating an existing allocation which may impact existing budgets, cost anomalies etc. These changes will come into effect upon saving.",
  DELETE_VALIDATION_DIALOG_TITLE: "Unable to delete some allocations",
  DELETE_VALIDATION_DIALOG_CONTENT:
    "Some allocations are used in other content types and can’t be deleted. Remove the allocations from where they are used in order to delete.",
  DONE: "Done",
  ALLOCATION_SAVE_CONFIRM: "Save and confirm",
  ALLOCATION_SAVE_AS_TITLE: "Save and name your allocation group",
  ALLOCATION_SAVE_AS_LABEL: "Save and name your allocation group",
};
