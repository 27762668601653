import CountUp from "react-countup";

export type CountUpContentProps = {
  number: number;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  duration?: number;
  spanStyle?: React.CSSProperties;
};

export default function CountUpContent({
  number,
  spanStyle,
  prefix = "",
  suffix = "",
  decimals = 0,
  duration = 0.1,
}: CountUpContentProps) {
  return (
    <CountUp
      start={0}
      end={number}
      delay={0}
      prefix={prefix}
      suffix={suffix}
      duration={duration}
      decimals={decimals}
      startOnMount
    >
      {({ countUpRef }) => (
        <div>
          <span style={{ ...spanStyle }} ref={countUpRef} />
        </div>
      )}
    </CountUp>
  );
}
