import { Box, Card, CardContent, Divider, Typography, useTheme } from "@mui/material";

interface ChartCardProps {
  title: string;
  children: React.ReactNode;
}

const ChartCard = ({ title, children }: ChartCardProps) => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
        <Box flexDirection="row" display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" fontWeight={500}>
            {title}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent sx={{ height: 200, px: 0, py: 1 }} style={{ paddingBottom: 8 }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default ChartCard;
