import { Box, CircularProgress, type CircularProgressProps, Typography, type TypographyOwnProps } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface CircularProgressWithLabelProps {
  value: number;
  size?: number;
  color?: CircularProgressProps["color"];
  backgroundColor?: string;
  typographyProps?: TypographyOwnProps;
}

export const CircularProgressWithLabel = ({
  value,
  size,
  color = "primary",
  backgroundColor = "transparent",
  typographyProps,
}: CircularProgressWithLabelProps) => (
  <Grid
    size={{
      sm: 4,
    }}
  >
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        size={size}
        variant="determinate"
        value={100}
        sx={{
          color: backgroundColor,
          position: "absolute",
        }}
      />
      <CircularProgress size={size} variant="determinate" value={value} color={color} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" {...typographyProps}>
          {value}%
        </Typography>
      </Box>
    </Box>
  </Grid>
);
