import { Link } from "react-router-dom";
import { type Cluster } from "@doitintl/cmp-models";
import { TableCell, Typography } from "@mui/material";

import useRouteMatchURL from "../../../../../Components/hooks/useRouteMatchURL";
import ClusterType from "../../../Clusters/Table/ClusterType";

interface Fsk8sClusterTableRowProps {
  row: Cluster;
}

const Fsk8sClusterTableRow = ({ row }: Fsk8sClusterTableRowProps) => {
  const routeMatchUrl = useRouteMatchURL();

  return (
    <>
      <TableCell>
        <Typography
          component={Link}
          to={`${routeMatchUrl}/clusters/${row.id}`}
          color="inherit"
          variant="body2"
          title="Click to enable features"
          sx={{ textDecorationColor: "inherit", cursor: "pointer" }}
        >
          {row.name}
        </Typography>
      </TableCell>
      <TableCell>
        <ClusterType type={row.type} />
      </TableCell>
      <TableCell align="right">10</TableCell>
      <TableCell align="right">0.1</TableCell>
      <TableCell align="right">250 Mi</TableCell>
    </>
  );
};

export default Fsk8sClusterTableRow;
