import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useAwsFeature } from "../../../Settings/AWS/hooks";

interface RequiredPermissionsDialogProps {
  feature: string;
  open: boolean;
  onClose: () => void;
}

const RequiredPermissionsDialog = ({ feature, open, onClose }: RequiredPermissionsDialogProps) => {
  const cloudConnectFeature = useAwsFeature(feature);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Required permissions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {cloudConnectFeature?.permissions.map((permission) => (
            <Typography color="text.primary" variant="body2" key={permission}>
              - {permission}
            </Typography>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequiredPermissionsDialog;
