import { memo, useCallback, useMemo, useState } from "react";

import { type AttributionFilter } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import FilterDialog from "../../../../Components/Dialogs/CloudAnalytics/FilterDialog";
import DimensionSelectionRow from "../../../../Components/DimensionSelectorWithOperator/DimensionSelectionRow";
import { type MetadataOption } from "../../../../types";
import { useFullScreen } from "../../../../utils/dialog";
import { getLetterForIndex } from "../../../../utils/string";
import { FilterModeOption } from "../types";

const LETTER_SIZE_BIG = 30;
const LETTER_SIZE_SMALL = 22;

type LetterStyleType = {
  width: number;
  fontWeight: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  height?: number;
  background?: string;
  borderRadius?: string;
};

const styles = {
  letterStyleAttribution: {
    width: LETTER_SIZE_SMALL,
    fontWeight: "bold",
  },
  letterStyleGuidedExperience: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: LETTER_SIZE_BIG,
    height: LETTER_SIZE_BIG,
    fontWeight: "bold",
    background: "rgba(0, 0, 0, 0.12)",
    borderRadius: "50%",
  },
};

type Props = {
  initialFilters: AttributionFilter[];
  dimensions?: MetadataOption[];
  excludeSelectMetadataIds?: Set<string>;
  guidedExperienceMode?: boolean;
  isEditMode?: boolean;
};

type DimensionSelectionRowWithLetterPrefixProps = {
  filter: AttributionFilter;
  letter: string;
  index: number;
  dimensions: MetadataOption[];
  options: MetadataOption[];
  disabled: boolean;
  excludeSelectMetadataIds?: Set<string>;
  letterStyle: LetterStyleType;
  onDimensionChanged: (from: MetadataOption, to: MetadataOption) => void;
  onDimensionCleared: (dimension: MetadataOption) => void;
  onModeChanged: (rule: FilterModeOption, filter: AttributionFilter, option: MetadataOption | null) => void;
  onEdit: (dimension: MetadataOption) => void;
};

const DimensionSelectionRowWithLetterPrefix = memo<DimensionSelectionRowWithLetterPrefixProps>(
  ({
    filter,
    letter,
    dimensions,
    options,
    disabled,
    excludeSelectMetadataIds,
    letterStyle,
    onDimensionChanged,
    onDimensionCleared,
    onModeChanged,
    onEdit,
  }) => {
    const md = dimensions.find((md) => md.id === filter.id);
    if (!md) return null;

    return (
      <Grid container size={12}>
        <Grid
          sx={{
            alignContent: { md: "center", xs: "flex-start" },
            mb: 0.5,
            mt: { xs: 1, md: 0 },
          }}
        >
          <Box sx={letterStyle}>{letter}</Box>
        </Grid>
        <Grid
          size={{
            xs: "grow",
            md: "auto",
          }}
        >
          <DimensionSelectionRow
            onDimensionChanged={(dimension) => {
              if (dimension) {
                onDimensionChanged(md, dimension);
              } else {
                onDimensionCleared(md);
              }
            }}
            selectedDimension={md}
            dimensions={options}
            disabled={disabled}
            excludeSelectMetadataIds={excludeSelectMetadataIds}
            onEdit={() => {
              onEdit(md);
            }}
            filter={filter}
            onModeChanged={onModeChanged}
          />
        </Grid>
      </Grid>
    );
  }
);

DimensionSelectionRowWithLetterPrefix.displayName = "DimensionSelectionRowWithLetterPrefix";

const createFilterFromDimension = (md: MetadataOption): AttributionFilter => ({
  id: md.id,
  field: md.data.field,
  type: md.data.type,
  key: md.data.key,
  allowNull: false,
  inverse: false,
  regexp: null,
  values: null,
});

export type DimensionEditOperation = {
  dimension: MetadataOption;
  newMode?: FilterModeOption;
  previousDimension?: MetadataOption;
};

const getMode = (rule: FilterModeOption): AttributionFilter["mode"] => {
  switch (rule) {
    case FilterModeOption.IS:
    case FilterModeOption.IS_NOT:
      return "is";
    case FilterModeOption.MATCHES_REGEX:
    case FilterModeOption.DOES_NOT_MATCHES_REGEX:
      return "regexp";
    case FilterModeOption.CONTAINS:
    case FilterModeOption.DOES_NOT_CONTAIN:
      return "contains";
    case FilterModeOption.STARTS_WITH:
      return "starts_with";
    case FilterModeOption.ENDS_WITH:
      return "ends_with";
    default:
      return "is";
  }
};

const getFilterModeValues = (mode: FilterModeOption) => ({
  inverse:
    mode === FilterModeOption.IS_NOT ||
    mode === FilterModeOption.DOES_NOT_CONTAIN ||
    mode === FilterModeOption.DOES_NOT_MATCHES_REGEX,
  mode: getMode(mode),
});

export default function useDimensionsSelection({
  isEditMode,
  initialFilters,
  dimensions = [],
  excludeSelectMetadataIds,
  guidedExperienceMode = false,
}: Props) {
  const [filters, setFilters] = useState(initialFilters);
  const [editOperation, setEditOperation] = useState<DimensionEditOperation | null>(null);

  const { isMobile } = useFullScreen();
  const [disabled, setDisabled] = useState(false);
  const letterStyle = guidedExperienceMode ? styles.letterStyleGuidedExperience : styles.letterStyleAttribution;

  const variablesArray = useMemo(() => filters.map((_, idx) => getLetterForIndex(idx)), [filters]);
  const filteredDimensions = useMemo(
    () => dimensions.filter((md) => md._visible || filters.findIndex((f) => f.id === md.id) > -1) ?? [],
    [dimensions, filters]
  );

  const handleChangeDimensionFilters = useCallback(
    (type: "regexp" | "values", value, inverse: boolean) => {
      if (!editOperation) {
        return;
      }
      setFilters((prevFilters) => {
        const dimension = editOperation.dimension;
        const filter = createFilterFromDimension(dimension);
        let removeFilter = false;
        filter.inverse = inverse;
        if (type === "regexp") {
          filter.values = null;
          filter.regexp = value;
        } else {
          removeFilter = value?.length === 0;
          filter.values = value;
          filter.regexp = null;
          if (dimension.data.nullFallback) {
            const nullIndex = filter.values?.findIndex((v) => v === dimension.data.nullFallback) ?? -1;
            filter.allowNull = nullIndex !== -1;
            if (filter.allowNull) {
              // move the 'nullFallback' to the end of the values array
              filter.values?.splice(nullIndex, 1);
              filter.values?.push(dimension.data.nullFallback);
            }
          }
        }
        const newFilters = prevFilters.slice();

        const idToCheck = editOperation.previousDimension?.id ?? dimension.id;
        const filterIndexToChange = filters.findIndex((f) => f.id === idToCheck);

        if (filterIndexToChange === -1) {
          filter.mode = FilterModeOption.IS;
          newFilters.push(filter);
          return newFilters;
        }

        if (removeFilter) {
          newFilters.splice(filterIndexToChange, 1);
        } else {
          const existingFilter = filters[filterIndexToChange];
          newFilters[filterIndexToChange] = {
            ...existingFilter,
            ...filter,
            ...getFilterModeValues(editOperation.newMode ?? FilterModeOption.IS),
          };
        }
        return newFilters;
      });
    },
    [editOperation, filters]
  );

  const handleModeChanged = useCallback(
    (mode: FilterModeOption, filter: AttributionFilter, option: MetadataOption | null) => {
      if (!option) {
        return;
      }

      const updateFilters = () => {
        setFilters((prev) =>
          prev.map((f) =>
            f.id === filter.id
              ? {
                  ...f,
                  ...getFilterModeValues(mode),
                }
              : f
          )
        );
      };

      if (mode === FilterModeOption.MATCHES_REGEX || mode === FilterModeOption.DOES_NOT_MATCHES_REGEX) {
        if (filter.regexp) {
          updateFilters();
        } else {
          option._regexp = " ";
          setEditOperation({
            dimension: option,
            newMode: mode,
          });
        }
      } else {
        if (filter.regexp) {
          option._regexp = null;
          setEditOperation({
            dimension: option,
            newMode: mode,
          });
        } else {
          updateFilters();
        }
      }
    },
    [setFilters]
  );

  const handleCloseFilterDialog = useCallback(() => {
    setEditOperation(null);
  }, [setEditOperation]);

  const handleEditDimensionFilters = useCallback(
    (dimension: MetadataOption) => {
      const filter = filters.find((f) => f.id === dimension?.id);

      const newDimension: MetadataOption = {
        ...dimension,
        _filter: filter?.values ?? null,
        _regexp: filter?.regexp ?? null,
      };
      setEditOperation({
        dimension: newDimension,
      });
    },
    [filters, setEditOperation]
  );

  const handleDimensionCleared = useCallback(
    (dimension: MetadataOption) => {
      const newFilters = filters.filter((f) => f.id !== dimension.id);
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const handleExistingDimensionChanged = useCallback(
    (from: MetadataOption, to: MetadataOption) => {
      setEditOperation({
        dimension: to,
        previousDimension: from,
      });
    },
    [setEditOperation]
  );

  const handleAddNewDimension = useCallback(
    (dimension: MetadataOption | null) => {
      if (dimension) {
        setEditOperation({
          dimension,
        });
      }
    },
    [setEditOperation]
  );

  const DimensionsSelection = useMemo(
    () => (
      <>
        <Grid container gap={1} alignContent="flex-start">
          {filters.map((filter, i) => (
            <DimensionSelectionRowWithLetterPrefix
              key={`filter-row-${variablesArray[i]}`}
              index={i}
              filter={filter}
              letter={variablesArray[i]}
              letterStyle={letterStyle}
              dimensions={dimensions}
              options={filteredDimensions}
              disabled={disabled || isMobile}
              excludeSelectMetadataIds={excludeSelectMetadataIds}
              onDimensionChanged={handleExistingDimensionChanged}
              onDimensionCleared={handleDimensionCleared}
              onModeChanged={handleModeChanged}
              onEdit={handleEditDimensionFilters}
            />
          ))}

          <Grid container spacing={filters.length > 0 ? 1 : 0} wrap="nowrap" sx={{ alignItems: "center" }} size={12}>
            {!isMobile && !guidedExperienceMode && filters.length > 0 && <Grid sx={{ width: letterStyle.width - 8 }} />}
            {!disabled && !isMobile && (
              <Grid display={{ xs: "none", md: "flex" }} size={{ xs: "grow", md: "auto" }}>
                <DimensionSelectionRow
                  selectedDimension={null}
                  dimensions={filteredDimensions}
                  disabled={false}
                  placeholder={allocationTxt.ADD_DIMENSION}
                  onEdit={() => {}}
                  excludeSelectMetadataIds={excludeSelectMetadataIds}
                  label={allocationTxt.ADD_DIMENSION}
                  onDimensionChanged={handleAddNewDimension}
                  onModeChanged={handleModeChanged}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {editOperation && (
          <FilterDialog
            open={true}
            {...(isEditMode ? { alertSeverity: "warning", alertText: allocationTxt.ALLOCATION_EDIT_FILTER } : {})}
            showExcludeSelection={false}
            selected={editOperation.dimension}
            onSave={handleChangeDimensionFilters}
            onClose={handleCloseFilterDialog}
            onCancel={handleCloseFilterDialog}
          />
        )}
      </>
    ),
    [
      filters,
      isMobile,
      guidedExperienceMode,
      letterStyle,
      filteredDimensions,
      disabled,
      excludeSelectMetadataIds,
      handleAddNewDimension,
      handleModeChanged,
      editOperation,
      isEditMode,
      handleChangeDimensionFilters,
      handleCloseFilterDialog,
      variablesArray,
      dimensions,
      handleExistingDimensionChanged,
      handleDimensionCleared,
      handleEditDimensionFilters,
    ]
  );

  return { DimensionsSelection, filters, setFilters, setDisabled };
}
