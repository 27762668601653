import {
  ClusterConnectionStatus,
  ClusterType,
  type ClusterWithCloudConnect,
  type KubernetesFeature,
} from "@doitintl/cmp-models";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { CopyCodeBlock } from "../../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import OpenGCPConsole from "../../../../../../Components/OpenGCPConsole/OpenGCPConsole";
import { downloadFile } from "../../../../../../utils/files";
import { OpenAWSConsole } from "../../../../../EKS/Components/OpenAWSConsole";
import { CREATE_ACCESS_ENTRY_BASE_COMMAND, getRbacYamlFileContent } from "../../../rbac";
import { getCodeVariablesForAwsAccessEntryCreation } from "./utils";

const kubeCtlBaseCommand = `kubectl apply -f $FILE_NAME`;

const commandExecutionHelperTexts: Record<ClusterType, string> = {
  [ClusterType.EKS]: "Run the following commands on the AWS Console or a method of your choice",
  [ClusterType.GKE]: "Run the following commands on the GCP Console or a method of your choice",
  [ClusterType.SELF_MANAGED]: "Run the following commands on the Kubernetes cluster",
};

interface RbacFeatureActivationProps {
  projectId: string | null;
  clusters: ClusterWithCloudConnect[];
  selectedFeatures: KubernetesFeature[];
}

const RbacFeatureActivation = ({ clusters, selectedFeatures, projectId }: RbacFeatureActivationProps) => {
  const firstCluster = clusters[0];
  const { type: clusterType } = firstCluster;
  const yamlFileName = `doit-${clusterType}-${projectId}-rbac-setup.yaml`;

  const needsToCreateAccessEntry =
    clusterType === ClusterType.EKS && clusters.some((c) => c.connectionStatus !== ClusterConnectionStatus.HEALTHY);

  const getCreateAccessEntryBaseCommand = () =>
    clusters
      .map((_, index) =>
        CREATE_ACCESS_ENTRY_BASE_COMMAND.replace(/\$CLUSTER_NAME/g, `$CLUSTER_${index}_NAME`)
          .replace(/\$ROLE_NAME/g, `$CLUSTER_${index}_ROLE_NAME`)
          .replace(/\$REGION/g, `$CLUSTER_${index}_REGION`)
      )
      .join(";\n\n");

  const handleDownloadYaml = () => {
    const { cloudConnect } = firstCluster;
    const content = getRbacYamlFileContent(clusterType, selectedFeatures, cloudConnect);
    downloadFile(yamlFileName, content, "text/yaml");
  };

  return (
    <Stack spacing={4} sx={{ pt: 3 }}>
      {needsToCreateAccessEntry && (
        <>
          <Stack spacing={2}>
            <Typography variant="body2">
              1. Run the following commands on the AWS Console or a method of your choice
            </Typography>
            <OpenAWSConsole />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Create a new access entry to associate the specified IAM principal with the EKS{" "}
              {clusters.length > 1 ? "clusters" : "cluster"}
            </Typography>
            <CopyCodeBlock
              maxHeight={300}
              base={getCreateAccessEntryBaseCommand()}
              variables={getCodeVariablesForAwsAccessEntryCreation(clusters, projectId ?? "")}
            />
          </Stack>
        </>
      )}

      <Stack spacing={2}>
        <Typography variant="body2">
          {needsToCreateAccessEntry ? 2 : 1}. Download the YAML file to your local device. You will need this to
          complete step {needsToCreateAccessEntry ? 3 : 2}.
        </Typography>
        <Button
          onClick={handleDownloadYaml}
          sx={{ width: "fit-content" }}
          startIcon={<DownloadIcon />}
          variant="outlined"
          color="primary"
          disabled={selectedFeatures.length === 0}
        >
          Download file
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body2">
          {needsToCreateAccessEntry ? 3 : 2}. {commandExecutionHelperTexts[clusterType]}
        </Typography>
        {clusterType === ClusterType.EKS ? <OpenAWSConsole /> : <OpenGCPConsole project={projectId} />}
      </Stack>

      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          Apply the Kubernetes permissions configuration file{" "}
          <strong style={{ fontWeight: 500 }}>{clusters.length > 1 ? "for each cluster" : "for the cluster"}</strong>
        </Typography>
        <CopyCodeBlock
          base={kubeCtlBaseCommand}
          variables={{
            $FILE_NAME: { value: yamlFileName, name: "File Name" },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default RbacFeatureActivation;
