import { type AlertColor } from "@mui/lab";

import {
  type Breakdown,
  type Insight,
  type SavingsPeriod,
  type SecurityRisks,
  type SecurityRisksBreakdownValue,
  type StatusDetails,
} from "./types";

export const totalFromBreakdown = (isOptimized: boolean, breakdown: Breakdown): number | null => {
  let savings = 0;

  if (!isOptimized && breakdown) {
    for (const savingsData of breakdown.data) {
      savings += savingsData.value;
    }
  }

  return savings;
};

export const totalCustomProblems = (insight: Insight): number | null => {
  if (!insight.results?.customProblems?.breakdown) {
    return null;
  }

  return totalFromBreakdown(insight.results.customProblems.isOptimized, insight.results.customProblems.breakdown);
};

export const totalSecurityRisks = (insight: Insight): number | null => {
  if (!insight.results?.securityRisks?.breakdown) {
    return null;
  }

  if (insight.results.securityRisks.isOptimized) {
    return 0;
  }

  let total = 0;
  insight.results.securityRisks.breakdown.data.forEach((e) => {
    const value = e.value.critical + e.value.high + e.value.medium + e.value.low;
    total += value;
  });
  return total;
};

export const totalSecurityRisksBreakdown = (insight: Insight): SecurityRisksBreakdownValue | null => {
  if (!insight.results?.securityRisks?.breakdown) {
    return null;
  }

  const breakdown: SecurityRisksBreakdownValue = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  };

  insight.results.securityRisks.breakdown.data.forEach((el) => {
    breakdown.critical += el.value.critical;
    breakdown.high += el.value.high;
    breakdown.medium += el.value.medium;
    breakdown.low += el.value.low;
  });
  return breakdown;
};

export const totalPotentialDailySavings = (insight: Insight): number | null => {
  if (!insight.results?.potentialDailySavings?.breakdown) {
    return null;
  }

  return totalFromBreakdown(
    insight.results?.potentialDailySavings?.isOptimized,
    insight.results?.potentialDailySavings?.breakdown
  );
};

export const sortByTotalPotentialDailySavings = (insights: Insight[], ascending = false) => {
  const sortedData = [...insights];

  // The "standard" order is descending - if we want to sort ascending instead, flip everything
  const multiplier = ascending ? -1 : 1;

  sortedData.sort((a, b) => {
    const savingsA = (totalPotentialDailySavings(a) || 0) + (totalCustomProblems(a) || 0);
    const savingsB = (totalPotentialDailySavings(b) || 0) + (totalCustomProblems(b) || 0);
    if (savingsA === 0) {
      // If neither having potential savings, keep order
      if (savingsB === 0) {
        return 0;
      }

      // If only B has savings, B should go first
      return 1 * multiplier;
    }

    // If only A has savings, A should go first
    if (savingsB === 0) {
      return -1 * multiplier;
    }

    // If they both have savings, whoever has more should go first
    return (savingsB - savingsA) * multiplier;
  });

  return sortedData;
};

export const calculateTotalSavingsForTimePeriod = (insights: Insight[], period: SavingsPeriod): number => {
  const multiplier = period === "daily" ? 1 : period === "monthly" ? 30 : 365;
  return insights.reduce((total, insight) => {
    const dailySavings =
      insight.results?.potentialDailySavings?.breakdown?.data.reduce((sum, dataItem) => sum + dataItem.value, 0) || 0;
    return total + dailySavings * multiplier;
  }, 0);
};

export const getAlert = (
  insight: Insight,
  statusDetails: StatusDetails | null = null
): { severity: AlertColor; label: string } | null => {
  // alerts for custom insights
  if (insight.customInsightAttributes && insight.displayStatus === "dismissed") {
    return {
      severity: "info",
      label: statusDetails
        ? `This insight was dismissed by ${statusDetails.userDisplayName} on ${statusDetails.date}.`
        : "This insight was dismissed.",
    };
  }

  if (insight.customInsightAttributes && insight.displayStatus === "optimized") {
    return {
      severity: "success",
      label: statusDetails
        ? `This insight was marked as optimized by ${statusDetails.userDisplayName} on ${statusDetails.date}.`
        : "This insight was marked as optimized.",
    };
  }

  // alerts for automated insights
  if (!insight.customInsightAttributes && insight.displayStatus === "dismissed") {
    return {
      severity: "info",
      label: "This insight was automatically dismissed by DoiT Cloud Intelligence™.",
    };
  }

  if (!insight.customInsightAttributes && insight.displayStatus === "optimized") {
    return {
      severity: "success",
      label: "This insight is currently optimized and no further action is required.",
    };
  }

  return null;
};

const securityRiskSeverityMapping = {
  "aws-trusted-advisor": {
    critical: "Critical",
    high: "High (red)",
    medium: "Medium (yellow)",
    low: "Low",
  },
  custom: {
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
  },
};

export const convertInsightWithSecurityRisks = (
  baseSecurityRisks: SecurityRisks | null,
  mapping: { [key: string]: string }
): { breakdown: Breakdown } | null => {
  if (!baseSecurityRisks?.breakdown) {
    return null;
  }

  const data = baseSecurityRisks.breakdown.data.map((data) => {
    const severity1orMore = Object.entries(data.value).filter(([_, value]) => value >= 1);
    return severity1orMore.map(([key, value]) => ({
      baseValue: 0,
      dimensionValues: [...data.dimensionValues, mapping[key]],
      value,
    }));
  });

  return {
    ...baseSecurityRisks,
    breakdown: {
      dimensions: [...(baseSecurityRisks.breakdown?.dimensions || []), "Severity"],
      data: data.flat(),
    },
  };
};

export const convertToDisplaySecurityRiskBreakdown = (
  providerId: string,
  result: SecurityRisks | null
): Breakdown | null => {
  if (result === null) {
    return null;
  }

  const mapping = securityRiskSeverityMapping[providerId] as {
    [key: string]: string;
  };

  // add an extra null check in case provider is not in the mapping
  const converted = mapping ? convertInsightWithSecurityRisks(result, mapping) : null;

  return converted ? converted.breakdown : null;
};
