import { useEffect, useState } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";
import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ForkRightRoundedIcon from "@mui/icons-material/ForkRightRounded";
import TransformIcon from "@mui/icons-material/Transform";
import { Box, ClickAwayListener, Grow, IconButton, Paper, Popper, Stack, Typography, useTheme } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";
import { type EdgeData } from "../../types";
import { useCloudflowOperations } from "../Common/CloudflowOperationsProvider";
import { useCloudflowState } from "../Common/hooks/useCloudflowState";

type Props = {
  data?: EdgeData;
  target: string;
};

const NodeOption = ({ icon, title, description, onClick }) => (
  <Stack
    direction="row"
    spacing={2}
    onClick={onClick}
    sx={{
      alignItems: "center",
      py: 1,
      px: 2,
      cursor: "pointer",
      "&:hover": { backgroundColor: "action.hover" },
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 1,
        borderColor: "divider",
        borderRadius: "50%",
        width: 36,
        height: 36,
      }}
    >
      {icon}
    </Box>
    <Stack>
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
        }}
      >
        {description}
      </Typography>
    </Stack>
  </Stack>
);

const AddNodePopover = ({ data, target }: Props) => {
  const { httpOperationLoading } = useCloudflowOperations();

  const { isPublished, isBlueprint } = useCloudflowState();

  const { shadows } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    data?.setInteractionEnabled(true);
  };
  const open = Boolean(anchorEl);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const handleOptionClick = (nodeType: CloudFlowNodeType) => {
    setAnchorEl(null);
    data?.handleAddNode(nodeType, target);
  };

  const handleAttachBlueprint = () => {
    setAnchorEl(null);
    data?.handleAttachBlueprint(target);
  };

  useEffect(() => {
    data?.setInteractionEnabled?.(!open);
  }, [open, data]);

  const isAddNodeDisabled = httpOperationLoading || isPublished || isBlueprint;

  const options = [
    {
      icon: <ApiOutlinedIcon color="primary" />,
      title: cloudflowTexts.PERFORM_ACTION,
      description: cloudflowTexts.PERFORM_ACTION_DESCRIPTION,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.ACTION);
      },
    },
    {
      icon: <AssignmentIcon color="primary" />,
      title: cloudflowTexts.ATTACH_BLUEPRINT,
      description: cloudflowTexts.ATTACH_BLUEPRINT_DESCRIPTION,
      onClick: () => {
        handleAttachBlueprint();
      },
    },
    {
      icon: <FilterAltIcon color="primary" />,
      title: cloudflowTexts.FILTER_RESULTS,
      description: cloudflowTexts.FILTER_RESULTS_DESCRIPTION,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.FILTER);
      },
    },
    {
      icon: <ForkRightRoundedIcon color="primary" />,
      title: cloudflowTexts.IF_STATEMENT,
      description: cloudflowTexts.IF_STATEMENT_DESCRIPTION,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.CONDITION);
      },
    },
    {
      icon: <TransformIcon color="primary" />,
      title: cloudflowTexts.TRANSFORM_DATA,
      description: cloudflowTexts.TRANSFORM_DATA_DESCRIPTION,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.TRANSFORMATION);
      },
    },
  ];

  return (
    <>
      <IconButton
        aria-describedby={open ? "add-node-popper" : undefined}
        disabled={isAddNodeDisabled}
        sx={{
          p: 0,
          "&:hover": {
            color: "primary.main",
            backgroundColor: "primary.contrastText",
          },
          pointerEvents: "all",
          backgroundColor: "primary.contrastText",
        }}
        onClick={handleClick}
      >
        <ControlPointIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"right"}
        disablePortal={false}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [10, 15],
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        sx={{
          boxShadow: shadows[24],
          borderRadius: 1,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <>
              <Box
                sx={{
                  position: "absolute",
                  width: 16,
                  height: 16,
                  boxSizing: "border-box",
                  color: "background.paper",
                  marginLeft: -1,
                  zIndex: -1,
                  "&::before": {
                    content: '""',
                    display: "block",
                    width: "100%",
                    height: "100%",
                    boxShadow: "none",
                    backgroundColor: "background.paper",
                    transform: "rotate(45deg)",
                    zIndex: -1,
                  },
                }}
                ref={setArrowRef}
              />
              <Paper elevation={2} sx={{ boxShadow: "none" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Stack
                    sx={{
                      py: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        px: 2,
                        pb: 1,
                      }}
                    >
                      What do you want CloudFlow to do?
                    </Typography>
                    {options.map((option, index) => (
                      <NodeOption key={index} {...option} />
                    ))}
                  </Stack>
                </ClickAwayListener>
              </Paper>
            </>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AddNodePopover;
