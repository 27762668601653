import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useModalManager } from "../Common/CloudflowModalsProvider";
import { ActionSearchItem } from "./ActionSearchItem";
import { ActionSearchServiceFilter } from "./ActionSearchServiceFilter";
import { useActionSearchDialogStateMachine } from "./useActionSearchDialogStateMachine";

export type OnOperationSelectFn = (operation: {
  operationName: string;
  provider: string;
  service: string;
  version: string;
}) => void;

export const ActionSearchDialog = ({ onOperationSelect }: { onOperationSelect: OnOperationSelectFn }) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const { closeModal } = useModalManager();

  const {
    query,
    setQuery,
    isLoading,
    items,
    disableNextPage,
    disablePrevPage,
    loadNextPage,
    loadPrevPage,
    onListItemClick,
    provider,
    service,
    goBackToProviders,
    goBackToServices,
    setService,
    showingQueryResults,
  } = useActionSearchDialogStateMachine(onOperationSelect);

  return (
    <Dialog
      open
      onClose={() => {
        closeModal("action");
      }}
      fullScreen={fullScreen}
      fullWidth
      slotProps={{ paper: { sx: { maxWidth: 704, height: 704 } } }}
    >
      <DialogTitle>Find actions to add to your CloudFlow</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal("action");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Paper
        elevation={0}
        sx={{
          px: 3,
          pb: 2,
          position: "relative",
        }}
      >
        <TextField
          variant="outlined"
          value={query}
          onChange={({ target: { value } }) => {
            setQuery(value);
          }}
          fullWidth
          autoFocus
          placeholder="Search actions"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {query && (
                    <IconButton
                      onClick={() => {
                        setQuery("");
                      }}
                      size="small"
                    >
                      <ClearIcon sx={{ width: 16, height: 16 }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          }}
        />
        {isLoading && <LinearProgress sx={{ position: "absolute", left: 0, bottom: 0, right: 0 }} />}
      </Paper>
      <DialogContent
        sx={{
          backgroundColor: "general.backgroundDark",
          py: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          alignSelf: "stretch",
        }}
        dividers
      >
        {(provider || showingQueryResults) && (
          <Stack direction="row" spacing={2} justifyContent="space-between" pt={1} height={48} flexShrink={0}>
            {(provider || showingQueryResults) && !service && (
              <Button startIcon={<ArrowBackIcon />} onClick={goBackToProviders}>
                Back to providers
              </Button>
            )}
            {provider && service && (
              <Button startIcon={<ArrowBackIcon />} onClick={goBackToServices}>
                Back to {provider.name} services
              </Button>
            )}
            {provider && (service || showingQueryResults) && (
              <ActionSearchServiceFilter
                emptyLabel={`All ${provider.name} services`}
                provider={provider}
                value={service}
                onChange={setService}
              />
            )}
          </Stack>
        )}
        {!isLoading && items.length === 0 && (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <Typography variant="subtitle2">No results found</Typography>
            <Typography variant="body2" color="text.secondary">
              Try a different term or browse for an action
            </Typography>
          </Stack>
        )}
        <List sx={{ flex: 1 }}>
          {items.map((item) => (
            <ActionSearchItem key={item.key} item={item} onClick={onListItemClick} />
          ))}
        </List>
      </DialogContent>
      {provider && !showingQueryResults && (
        <DialogActions sx={{ py: "1px", pr: 2 }}>
          <IconButton onClick={loadPrevPage} disabled={disablePrevPage} size="small">
            <ChevronLeftIcon />
          </IconButton>
          <IconButton onClick={loadNextPage} disabled={disableNextPage} size="small">
            <ChevronRightIcon />
          </IconButton>
        </DialogActions>
      )}
    </Dialog>
  );
};
