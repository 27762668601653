import { CardContent, Divider, Typography, useTheme } from "@mui/material";

interface CardTitleProps {
  children: React.ReactNode;
}

const CardTitle = ({ children }: CardTitleProps) => {
  const theme = useTheme();

  const content = typeof children === "string" ? <Typography variant="h4">{children}</Typography> : children;

  return (
    <>
      <CardContent sx={{ backgroundColor: theme.palette.general.backgroundDark, width: "100%" }}>{content}</CardContent>
      <Divider sx={{ width: "100%" }} />
    </>
  );
};

export default CardTitle;
