import React, { useEffect, useMemo, useState } from "react";

import { type AzureFeaturePermission } from "@doitintl/cmp-models";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import type { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert/Alert";
import type { OverridableStringUnion } from "@mui/types";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { consoleErrorWithSentry } from "../../../utils";
import { getAzureFeaturePermission } from "./db";
import { useAzureConnectApi } from "./hooks";
import { type AzureRole, type AzureSubscription, type RoleRequest } from "./types";

const AZURE_FEATURE_APP = {
  displayName: "Doit International",
  clientId: "15c1455a-e8eb-4479-ab5b-cea01e7aeede",
  tenantId: "5c0d2242-8740-49a1-9284-8dbb6365df6e",
};

const AzureResources = () => {
  const [tenantId, setTenantId] = useState("");
  const [mgmtGroupId, setMgmtGroupId] = useState("");
  const [roles, setRoles] = useState<AzureRole[]>([{ scope: "managementGroup", roleId: "" }]);
  const [roleRequestData, setRoleRequestData] = useState<RoleRequest>({
    roles,
  } as RoleRequest);
  const [copySuccess, setCopySuccess] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>("success");
  const [azureFeaturePermission, setAzureFeaturePermission] = useState<AzureFeaturePermission | undefined>(undefined);
  const [subscriptions, setSubscription] = useState<AzureSubscription[] | undefined>(undefined);

  const showSnackbar = (severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>, title: string) => {
    setSnackbarSeverity(severity);
    setSnackbarTitle(title);
    setOpenSnackbar(true);
  };

  const customerId = useCustomerId();
  useEffect(() => {
    setRoleRequestData((prev) => ({ ...prev, customerId }));
  }, [customerId]);

  useEffect(() => {
    getAzureFeaturePermission("advisor")
      .then((app) => {
        setAzureFeaturePermission(app);
      })
      .catch((err) => {
        showSnackbar("error", "error reading applications");
        consoleErrorWithSentry(err);
      });
  }, []);

  const handleRoleIdChange = (index, event) => {
    const newRoleIds = [...roles];
    newRoleIds[index] = { roleId: event.target.value, scope: "managementGroup" };
    setRoles(newRoleIds);
    setRoleRequestData((prev) => ({ ...prev, roles: newRoleIds }));
  };

  const handleAddRole = () => {
    setRoles([...roles, { scope: "managementGroup", roleId: "" }]);
  };

  const azureConnectApi = useAzureConnectApi();

  const handleSubmit = async () => {
    try {
      await azureConnectApi.addAzureRole(roleRequestData);
      showSnackbar("success", "Role added successfully");
    } catch (err) {
      showSnackbar("error", "Error adding role");
      consoleErrorWithSentry(err);
    }
  };

  const handleCancel = () => {
    setTenantId("");
    setMgmtGroupId("");
    setRoles([{ scope: "managementGroup", roleId: "" }]);
    setRoleRequestData({ customerId, roles } as RoleRequest);
  };

  const handleTestConnection = async () => {
    try {
      setSubscription(undefined);
      const subscription = await azureConnectApi.listSubscription(
        roleRequestData?.tenantId || "",
        AZURE_FEATURE_APP.clientId
      );
      if (subscription.length) {
        showSnackbar("success", "Test Connection passed");
        setSubscription(subscription);
      }
    } catch (err) {
      showSnackbar("error", "Test Connection failed");
    }
  };

  const handleCloseSnackbar = (_event: React.SyntheticEvent<Element, Event> | Event, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarTitle("");
    setOpenSnackbar(false);
  };

  const isFormValid = useMemo<boolean>(
    () => azureFeaturePermission !== undefined && mgmtGroupId !== "" && tenantId !== "",
    [azureFeaturePermission, mgmtGroupId, tenantId]
  );
  const createServicePrincipalCmd = `az ad sp create --id ${AZURE_FEATURE_APP.clientId} # ${AZURE_FEATURE_APP?.displayName}`;

  const assignRolesToManagementGroupCmds = useMemo<string[]>(
    () =>
      azureFeaturePermission?.roles.map(
        (role) =>
          role.scope === "managementGroup"
            ? `az role assignment create --assignee  ${AZURE_FEATURE_APP.clientId} --role ${role.name} --scope /providers/Microsoft.Management/managementGroups/${mgmtGroupId}`
            : "" // todo - add subscription scope commands and resource group commands
      ) || [],
    [azureFeaturePermission, mgmtGroupId]
  );

  const handleCopy = async (value: string, description: string) => {
    try {
      await navigator.clipboard.writeText(value);
      setCopySuccess(description);
      setTimeout(() => {
        setCopySuccess("");
      }, 3000);
    } catch (err) {
      setCopySuccess("Copy failed!");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 4,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Tenant ID"
          variant="outlined"
          value={tenantId}
          onChange={(e) => {
            setTenantId(e.target.value);
            setRoleRequestData((prev) => ({ ...prev, tenantId: e.target.value }));
          }}
        />
        <TextField
          label="Management Group ID"
          variant="outlined"
          value={mgmtGroupId}
          onChange={(e) => {
            setMgmtGroupId(e.target.value);
            setRoleRequestData((prev) => ({ ...prev, managementGroupId: e.target.value }));
          }}
        />

        {isFormValid && (
          <Paper
            elevation={3}
            sx={{
              mt: 4,
              p: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Generated Code
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
              <Typography variant="body1">{createServicePrincipalCmd}</Typography>
              <IconButton
                onClick={() => handleCopy(createServicePrincipalCmd, "ad sp create cmd copied")}
                size="small"
                sx={{ borderRadius: "50%" }}
                aria-label="Copy Tenant ID"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>
            {assignRolesToManagementGroupCmds.map((roleAssignCmd) => (
              <Box key={roleAssignCmd} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body1">{roleAssignCmd}</Typography>
                <IconButton
                  onClick={() => handleCopy(roleAssignCmd, "role assignment cmd copied")}
                  size="small"
                  sx={{ borderRadius: "50%" }}
                  aria-label="Copy Management Group ID"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Paper>
        )}

        {roles.map((role, index) => (
          <TextField
            key={index}
            label={`Role ID`}
            variant="outlined"
            value={role.roleId}
            onChange={(event) => {
              handleRoleIdChange(index, event);
            }}
          />
        ))}

        <Button onClick={handleAddRole}>Add Role</Button>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="outlined" color="primary" onClick={handleTestConnection}>
            Test Connection
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarTitle}
        </Alert>
      </Snackbar>

      {copySuccess && (
        <Typography variant="caption" sx={{ display: "block", mt: 2, textAlign: "center" }}>
          {copySuccess}
        </Typography>
      )}

      {subscriptions?.length && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Display Name</strong>
                </TableCell>
                <TableCell>
                  <strong>State</strong>
                </TableCell>
                <TableCell>
                  <strong>Subscription ID</strong>
                </TableCell>
                <TableCell>
                  <strong>Tenant ID</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((sub) => (
                <TableRow key={sub.subscriptionId}>
                  <TableCell>{sub.displayName}</TableCell>
                  <TableCell>{sub.state}</TableCell>
                  <TableCell>{sub.subscriptionId}</TableCell>
                  <TableCell>{sub.tenantId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default AzureResources;
