import { useState } from "react";

import { useHistory } from "react-router";
import { type Cluster } from "@doitintl/cmp-models";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import PercentileSelector, { Percentile } from "./Components/PercentileSelector";
import TimeRangeSelector, { TimeRange } from "./Components/TimeRangeSelector";

interface ClusterDetailHeaderProps {
  cluster: Cluster;
}

const ClusterDetailHeader = ({ cluster }: ClusterDetailHeaderProps) => {
  const history = useHistory();
  const { customer } = useCustomerContext();

  const [percentile, setPercentile] = useState(Percentile.P_99_9);
  const [timeRange, setTimeRange] = useState(TimeRange.T_7D);

  const goBack = () => {
    history.push(`/customers/${customer.id}/flexsave-kubernetes`);
  };

  return (
    <Box justifyContent="space-between" display="flex" alignItems="center" flexDirection="row">
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1">{cluster.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <PercentileSelector value={percentile} onChange={setPercentile} />
        <TimeRangeSelector value={timeRange} onChange={setTimeRange} />
      </Stack>
    </Box>
  );
};

export default ClusterDetailHeader;
