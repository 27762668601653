import type { AxiosInstance } from "axios";

import type { AzureSubscription, RoleRequest } from "./types";

export class AzureConnectApi {
  private readonly apiPrefix: string;

  constructor(private readonly api: AxiosInstance) {
    this.api = api;
    this.apiPrefix = `/v1/azure`;
  }

  public async listSubscription(tenantId: string, clientId: string): Promise<AzureSubscription[]> {
    const response = await this.api.post(`${this.apiPrefix}/list-subscriptions`, {
      tenant_id: tenantId,
      client_id: clientId,
    });
    return response.data as AzureSubscription[];
  }

  public async addAzureRole(roleRequest: RoleRequest) {
    await this.api.post(`${this.apiPrefix}/customer/${roleRequest.customerId}/role`, {
      customer_id: roleRequest.customerId,
      tenant_id: roleRequest.tenantId,
      management_group_id: roleRequest.managementGroupId,
      subscription_id: roleRequest.subscriptionId,
      resource_id: roleRequest.resourceId,
      roles: roleRequest.roles,
    });
  }
}
