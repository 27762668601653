import { type JSX } from "react";

import { Box, Card, Stack, type StackOwnProps } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type SxProps } from "@mui/system";

import CountUpContent, { type CountUpContentProps } from "./CountUpContent";
import { CountUpNumber } from "./CountUpNumber";
import { CountUpText, type CountUpTextProps } from "./CountUpText";

export type CountUpCardProps = CountUpContentProps &
  CountUpTextProps & {
    childAboveContent?: JSX.Element;
    childBeforeContent?: JSX.Element;
    childAfterContent?: JSX.Element;
    childRightToText?: JSX.Element;
    childBelowText?: JSX.Element;
    appendChildrenDirection?: "row" | "column";
    contentPadding?: number;
    numberColor?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
    numberOnClick?: () => void;
    sx?: SxProps<Theme>;
    cardSx?: SxProps<Theme>;
    contentStyle?: React.CSSProperties;
    spacing?: StackOwnProps["spacing"];
  };

function CountUpCard({
  text,
  tooltipText,
  number,
  numberColor = "textPrimary",
  numberOnClick,
  prefix = "",
  suffix = "",
  decimals,
  duration,
  childAboveContent,
  childBeforeContent,
  childAfterContent,
  childRightToText,
  childBelowText,
  sx,
  cardSx,
  contentStyle,
  spacing,
  appendChildrenDirection = "row",
  contentPadding = 2,
}: CountUpCardProps) {
  return (
    <Card sx={cardSx}>
      {childAboveContent}
      <Box
        data-testid={tooltipText || "data-card"}
        sx={[
          {
            p: contentPadding,
            display: "flex",
            flexDirection: appendChildrenDirection,
            justifyContent: "space-between",
            width: "100%",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {childBeforeContent ?? <div />}
        <Stack
          sx={{
            textAlign: "center",
          }}
          spacing={spacing}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CountUpNumber numberOnClick={numberOnClick} numberColor={numberColor}>
              <CountUpContent
                number={number}
                decimals={decimals}
                prefix={prefix}
                suffix={suffix}
                duration={duration}
                spanStyle={contentStyle}
              />
            </CountUpNumber>

            {childRightToText}
          </Box>
          {!!text && <CountUpText text={text} tooltipText={tooltipText} />}
          {childBelowText}
        </Stack>
        {childAfterContent ?? <div />}
      </Box>
    </Card>
  );
}

export default CountUpCard;
