import { type Cluster } from "@doitintl/cmp-models";
import { Skeleton, Stack, Typography } from "@mui/material";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { useClusters } from "../../../hooks";
import Fsk8sClusterTableRow from "./TableRow";

const Fsk8sClusterTable = () => {
  const [clusters, loading] = useClusters();

  const itemDescriptions: FilterTableItemDescriptor<Cluster>[] = [
    {
      headerText: "Cluster name",
      value: "name",
      filterType: "string",
    },
    {
      headerText: "Type",
      value: "type",
      filterType: "string",
    },
    {
      headerText: "Workload count",
      value: "@",
      filterType: "number",
      headerStyle: { align: "right" },
    },
    {
      headerText: "CPU utilization",
      value: "@",
      filterType: "number",
      headerStyle: { align: "right" },
    },
    {
      headerText: "Memory utilization",
      value: "@",
      filterType: "number",
      headerStyle: { align: "right" },
    },
  ];

  if (loading) {
    return <Skeleton variant="rectangular" height={400} />;
  }

  const { headerColumns, filters } = generateHeadersAndFilters(itemDescriptions);

  return (
    <Stack>
      <Typography variant="h4" sx={{ mb: -2 }}>
        Clusters ({clusters?.length ?? 0})
      </Typography>
      <FilterTable
        rowComponent={Fsk8sClusterTableRow}
        persistenceKey="fsk8s-clusters-table"
        tableItems={clusters}
        headerColumns={headerColumns}
        filterColumns={filters}
        fillEmptySpace={false}
      />
    </Stack>
  );
};

export default Fsk8sClusterTable;
