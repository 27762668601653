import { type ClusterWithCloudConnect, type KubernetesFeature } from "@doitintl/cmp-models";
import { CircularProgress, Stack, Typography } from "@mui/material";

import Tabs, { type TabConfig } from "../../../../Components/Tabs";
import { AwsAccessEntryActivation, RbacFeatureActivation } from "./components/FeatureActivation";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

type EnableFeaturesStepProps = {
  projectId: string | null;
  clusters: ClusterWithCloudConnect[] | null;
  selectedFeatures: KubernetesFeature[];
  title?: string;
};

const EnableFeaturesStep = ({ projectId, clusters, selectedFeatures, title }: EnableFeaturesStepProps) => {
  if (!clusters || clusters.length === 0) {
    return <CircularProgress />;
  }

  const cluster = clusters[0];

  const tabs: TabConfig[] = [
    {
      label: "Kubernetes RBAC",
      key: "kubernetes-rbac",
      content: <RbacFeatureActivation projectId={projectId} clusters={clusters} selectedFeatures={selectedFeatures} />,
    },
  ];

  if (cluster.type === "EKS") {
    tabs.unshift({
      label: "AWS access entry",
      key: "aws-access-entry",
      content: <AwsAccessEntryActivation accountId={projectId} clusters={clusters} />,
    });
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">
          {title ?? `Enable selected ${selectedFeatures.length > 1 ? "features" : "feature"}`}
        </Typography>
        <SelectedAccountAndCluster projectId={projectId} selectedClusters={clusters} />
      </Stack>

      {tabs.length > 1 ? <Tabs tabs={tabs} /> : tabs[0].content}
    </Stack>
  );
};

export default EnableFeaturesStep;
