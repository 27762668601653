import { useState } from "react";

import { useHistory } from "react-router";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Collapse, Typography } from "@mui/material";

import { Alert } from "../../../Components/Alerts";
import { useCustomerContext } from "../../../Context/CustomerContext";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { type InsightsAddPermissionsProperties } from "../types";

type Props = {
  accountsMissingPermissions: string[];
  insightsEvent?: InsightsAddPermissionsProperties;
};

export const MissingCostOptHubPermissionsAlert: React.FC<Props> = ({ accountsMissingPermissions, insightsEvent }) => {
  const history = useHistory();
  const { trackEvent } = useSegmentTrackEvent();

  const [hidden, setHidden] = useState(false);
  const { customer } = useCustomerContext();

  const handleClick = () => {
    trackEvent("Insights Add Permissions Clicked", insightsEvent);
    history.push(`/customers/${customer.id}/settings/aws?filter=${accountsMissingPermissions.toString()}`);
  };

  return (
    <Collapse in={!hidden}>
      <Alert
        sx={{ mt: -2, mb: 4 }}
        severity="info"
        onClose={() => {
          setHidden(true);
        }}
        title="Permissions needed"
        icon={<InfoOutlinedIcon fontSize="inherit" />}
        buttonProps={{
          text: "Add permissions",
          onClick: handleClick,
          key: "info",
          color: "inherit",
          sx: { whiteSpace: "nowrap" },
        }}
      >
        <Typography color="inherit" variant="body2">
          Enable Cost Optimization Hub insights on your accounts that have opted in to Cost Optimization Hub and AWS
          Compute Optimizer to get recommendations here.
        </Typography>
      </Alert>
    </Collapse>
  );
};
