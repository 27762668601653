import { useRef, useState } from "react";

import { EarlyAccessFeature } from "@doitintl/cmp-models";
import { Alert, Box, Collapse, FormControlLabel, Link, Radio, RadioGroup, Stack, Typography } from "@mui/material";

import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import useUnmountEffect from "../../../../Components/hooks/useUnmountEffect";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import mixpanel from "../../../../utils/mixpanel";
import { addChannelsDoc } from "../../../Settings/AWS/db";
import { useAWSAccountInfo } from "../../../Settings/AWS/hooks";
import { UpdateErrorAlert } from "../../../Settings/AWS/ManagePermissions/Alerts/UpdateErrorAlert";
import {
  getQuickCreateUrl,
  getQuickUpdateUrl,
} from "../../../Settings/AWS/ManagePermissions/FeaturesSelection/cloudFormationHelper";
import { listenForStackCreationUpdate } from "../../../Settings/AWS/ManagePermissions/FeaturesSelection/listeners";
import { AWSFeatureName } from "../../../Settings/AWS/types";
import LoadingAlert from "./Alerts/LoadingAlert";
import AwsCliConnection from "./AwsCliConnection";
import AwsConsoleConnection from "./AwsConsoleConnection";
import RequiredPermissionsDialog from "./RequiredPermissionsDialog";

interface AwsFeatureConnectionProps {
  featureKey: AWSFeatureName;
  accountId: string | null;
  onUpdateFeature: () => void;
  header?: string;
  instructions?: React.ReactNode;
}

enum ConnectionOption {
  AWS = "aws",
  CLI = "cli",
}

enum Mode {
  Selecting,
  WaitingForUpdate,
  UpdateFailed,
  UpdateSuccess,
}

const AwsFeatureConnection = ({
  featureKey,
  accountId,
  onUpdateFeature,
  header,
  instructions,
}: AwsFeatureConnectionProps) => {
  const { customer } = useCustomerContext();
  const isFSK8SFeatureEnabled = useFeatureFlag(EarlyAccessFeature.FSK8S);
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const [enabledFeatures, roleName, accountLoading] = useAWSAccountInfo(accountId || "");

  const unsubscribeListener = useRef<() => void>(() => {});

  const [requiredPermissionsDialogOpen, setRequiredPermissionsDialogOpen] = useState(false);
  const [selectedConnectionOption, setSelectedConnectionOption] = useState<ConnectionOption>(ConnectionOption.AWS);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [mode, setMode] = useState<Mode>(Mode.Selecting);

  const featureIsHealthy =
    (enabledFeatures.includes(AWSFeatureName.cloud_diagrams) && enabledFeatures.length > 0) ||
    mode === Mode.UpdateSuccess;

  const mixpanelFeatureId = featureKey.replace(/_/g, "-");

  useUnmountEffect(() => {
    unsubscribeListener.current();
  });

  const onUpdate = () => {
    setMode(Mode.UpdateSuccess);
    onUpdateFeature();
  };

  const startListeningForChanges = (stackName: string) => {
    const onChangeDetectedCallback = (error: string | undefined) => {
      unsubscribeListener.current();
      if (error) {
        setErrorMessage(error);
        setMode(Mode.UpdateFailed);
      } else {
        onUpdate();
      }
    };

    unsubscribeListener.current();
    addChannelsDoc(customer.id, currentUser.uid, stackName);
    setMode(Mode.WaitingForUpdate);
    unsubscribeListener.current = listenForStackCreationUpdate(
      customer.id,
      currentUser.uid,
      stackName,
      onChangeDetectedCallback
    );
  };

  const handleOpenRequiredPermissions = () => {
    setRequiredPermissionsDialogOpen(true);
  };

  const handleConnectionOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedConnectionOption(event.target.value as ConnectionOption);
  };

  const navigateToAwsQuickLinkPage = () => {
    mixpanel.track(`${mixpanelFeatureId}.aws-console.set-up-${mixpanelFeatureId}-on-aws-console`);

    const [url, stackName] = roleName
      ? getQuickUpdateUrl(customer.id, roleName, [featureKey], isFSK8SFeatureEnabled, "")
      : getQuickCreateUrl(customer.id, [featureKey], isFSK8SFeatureEnabled, "");
    window.open(url, "_blank");

    setAlertMessage("After you create stack on AWS, please wait as this may take up to 30 seconds...");

    startListeningForChanges(stackName);
  };

  const onCloseAlertClicked = () => {
    unsubscribeListener.current();
    setMode(Mode.Selecting);
  };

  if (accountLoading) {
    return null;
  }

  return (
    <>
      {!featureIsHealthy && (
        <Stack spacing={1}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="subtitle1" fontWeight={500}>
              {header ?? `How do you want to connect ${featureKey}?`}
            </Typography>
            <Link onClick={handleOpenRequiredPermissions} sx={{ textDecoration: "none", cursor: "pointer" }}>
              List of required permissions
            </Link>
          </Box>
          <RadioGroup name="connectOptions" value={selectedConnectionOption} onChange={handleConnectionOptionChange}>
            <FormControlLabel
              value={ConnectionOption.AWS}
              control={<Radio />}
              label="AWS Console (CloudFormation stack)"
              data-testid="aws-console"
            />
            <FormControlLabel
              value={ConnectionOption.CLI}
              control={<Radio />}
              label="CLI commands (CloudFormation stack)"
              data-testid="cli"
            />
          </RadioGroup>

          {instructions}

          {selectedConnectionOption === ConnectionOption.AWS && (
            <AwsConsoleConnection onOpenConsole={navigateToAwsQuickLinkPage} />
          )}

          {selectedConnectionOption === ConnectionOption.CLI && (
            <AwsCliConnection featureKey={featureKey} mixpanelFeatureId={mixpanelFeatureId} roleToUpdate={roleName} />
          )}
        </Stack>
      )}

      <Collapse
        in={mode === Mode.WaitingForUpdate}
        style={{ marginTop: mode === Mode.WaitingForUpdate ? undefined : 0 }}
      >
        <LoadingAlert message={alertMessage} onClose={onCloseAlertClicked} />
      </Collapse>

      <Collapse in={mode === Mode.UpdateSuccess} style={{ marginTop: mode === Mode.UpdateSuccess ? undefined : 0 }}>
        <Alert severity="success">
          <Typography>Your AWS account was successfully connected</Typography>
        </Alert>
      </Collapse>

      <Collapse in={mode === Mode.UpdateFailed} style={{ marginTop: mode === Mode.UpdateFailed ? undefined : 0 }}>
        <UpdateErrorAlert error={errorMessage} onClose={onCloseAlertClicked} />
      </Collapse>

      <RequiredPermissionsDialog
        feature={featureKey}
        open={requiredPermissionsDialogOpen}
        onClose={() => {
          setRequiredPermissionsDialogOpen(false);
        }}
      />
    </>
  );
};

export default AwsFeatureConnection;
