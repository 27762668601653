import { useEffect, useState } from "react";

import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../../../Context/TierProvider";
import { consoleErrorWithSentry } from "../../../../../utils";
import { useRelevantTrustedAdvisorCustomerAccounts } from "../../../../Insights/api";
import { getAllFeaturesDetails } from "../../db";
import { type Feature } from "../../types";
import { FeaturesSelectionRow } from "./FeaturesSelectionRow";

type FeaturesTableProps = {
  accountSupportedFeatures: string[];
  disabled: boolean;
  onSelectionChanged: (selectedFeatures: string[]) => void;
  onRealTimeDataBucketNameChanged: (bucket: string) => void;
  realTimeDataBucketName: string;
};

/**
 * Table to show possible AWS features that can be added to account
 * @param accountSupportedFeatures already supported features names of account (will be disabled)
 * @param disabled - if true disable selecting all features
 * @param onSelectionChanged - called on every checkbox click of one of the features
 * @param onRealTimeDataBucketNameChanged
 * @param realTimeDataBucketName
 * @constructor
 */
export const FeaturesTable = ({
  accountSupportedFeatures,
  disabled,
  onSelectionChanged,
  onRealTimeDataBucketNameChanged,
  realTimeDataBucketName,
}: FeaturesTableProps) => {
  const [allFeatures, setAllFeatures] = useState<Feature[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const { customer } = useCustomerContext();
  const isTrustedAdvisorRelevantCustomer = !!useRelevantTrustedAdvisorCustomerAccounts()?.length;

  const isEntitledToRealTimeData = useIsFeatureEntitled("governance:costAnomalies:realTime");
  const isEntitledToSpotScaling = useIsFeatureEntitled("savings:spotScaling");

  useEffect(() => {
    const earlyAccessFeatures = customer.earlyAccessFeatures;
    getAllFeaturesDetails(
      earlyAccessFeatures,
      isTrustedAdvisorRelevantCustomer,
      isEntitledToRealTimeData,
      isEntitledToSpotScaling
    )
      .then((features) => {
        setAllFeatures(features);
        setLoading(false);
      })
      .catch(consoleErrorWithSentry);
  }, [customer, isTrustedAdvisorRelevantCustomer, isEntitledToRealTimeData, isEntitledToSpotScaling]);

  const handleFeatureSelection = (featureName: string, checked: boolean) => {
    let newSelectedFeatures: string[];

    if (checked) {
      newSelectedFeatures = [...selectedFeatures, featureName];
    } else {
      newSelectedFeatures = selectedFeatures.filter((feature) => feature !== featureName);
    }
    setSelectedFeatures(newSelectedFeatures);
    onSelectionChanged(newSelectedFeatures);
  };

  return (
    <>
      {loading && <Skeleton variant="rectangular" width="100%" height={250} />}
      <Box hidden={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={15} />
              <TableCell>Available features</TableCell>
              <TableCell align="right" padding="checkbox">
                Include
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allFeatures.map((feature) => (
              <FeaturesSelectionRow
                key={feature.name}
                feature={feature}
                disabled={disabled}
                supportedFeatures={accountSupportedFeatures}
                handleFeatureSelection={handleFeatureSelection}
                realTimeDataBucketName={realTimeDataBucketName}
                onRealTimeDataBucketNameChanged={onRealTimeDataBucketNameChanged}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
