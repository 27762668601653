import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import psLogo from "../../../../assets/perfect-scale.png";

const Fsk8sBanner = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      minHeight={200}
      sx={{ backgroundColor: theme.palette.general.backgroundDark, width: "100%" }}
      p={4}
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <Stack
        direction={isSmallScreen ? "column-reverse" : "row"}
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack maxWidth={640} spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h3">Unlock Deeper Insights & Optimize Your Kubernetes Operations</Typography>
            <Typography variant="body1">
              Gain full visibility, smarter recommendations, and cost-saving opportunities—built for DevOps teams who
              want more control, better efficiency, and actionable data.
            </Typography>
          </Stack>
          <Button variant="contained" color="primary" sx={{ width: "fit-content" }}>
            Contact sales
          </Button>
        </Stack>
        <img src={psLogo} alt="PerfectScale by DoiT" height={61} />
      </Stack>
    </Box>
  );
};

export default Fsk8sBanner;
