import { useState } from "react";

import { type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Collapse, Typography } from "@mui/material";

import { Alert } from "../../../Components/Alerts";
import { useCustomerContext } from "../../../Context/CustomerContext";

export const MissingCloudConnectPermissionsAlert = (data: {
  unhealthyOrganization: ModelIdRef<CustomerModelCloudConnectModel> | null;
}) => {
  const [hidden, setHidden] = useState<boolean>(false);
  const { customer } = useCustomerContext();

  let buttonText = "Connect organization or project";
  let message =
    "Connect your organization or project and enable Kubernetes insights to get recommendations for your Google Kubernetes Engine (GKE) clusters";
  let link = `/customers/${customer.id}/settings/gcp`;

  if (data.unhealthyOrganization !== null) {
    buttonText = "Add permissions to organization";
    message =
      "Enable Kubernetes insights on your organization to get recommendations for your Google Kubernetes Engine (GKE) clusters.";
    link = `/customers/${customer.id}/settings/gcp/connect/organization/${data.unhealthyOrganization.id}`;
  }

  return (
    <Collapse in={!hidden}>
      <Alert
        sx={{ mt: -2, mb: 4 }}
        severity="info"
        onClose={() => {
          setHidden(true);
        }}
        title="Permissions needed"
        icon={<InfoOutlinedIcon fontSize="inherit" />}
        buttonProps={{
          text: buttonText,
          href: link,
          key: "info",
          color: "inherit",
          sx: { whiteSpace: "nowrap" },
        }}
      >
        <Typography color="inherit" variant="body2">
          {message}
        </Typography>
      </Alert>
    </Collapse>
  );
};
