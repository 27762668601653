import { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router";
import {
  type Cluster,
  ClusterType,
  type ClusterWithCloudConnect,
  type KubernetesFeature,
  KubernetesFeatureStatus,
} from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { Stepper } from "../../../Components/Stepper";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { importAWSAccountCD } from "../../CloudDiagrams/import";
import { useAWSAccounts } from "../../Settings/AWS/hooks";
import { activateCluster } from "../api";
import { useClustersWithCloudConnect, useKubernetesFeatures } from "../hooks";
import { useKubernetesClusterActivation } from "./hooks";
import { DiscoverEksClustersStep, EnableFeaturesStep, SelectClustersStep, TestConnectionStep } from "./Steps";
import { type StepConfig, useSteps } from "./utils";

const EksCloudDiagramsClusterActivation = () => {
  const { currentStep, selectedProjectId, setCurrentStep } = useKubernetesClusterActivation(ClusterType.EKS);

  const location = useLocation();
  const history = useHistory();
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const [clusters] = useClustersWithCloudConnect();
  const [availableFeatures] = useKubernetesFeatures();
  const [accounts] = useAWSAccounts();

  const [selectedClusterIds, setSelectedClusterIds] = useState<Cluster["id"][]>([]);
  const [clusterSelectorTouched, setClusterSelectorTouched] = useState(false);
  const [cloudDiagramsImportLoading, setCloudDiagramsImportLoading] = useState(false);
  const [diagramAlreadyExists, setDiagramAlreadyExists] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const diagramAlreadyExists = queryParams.get("diagramExists");
    setDiagramAlreadyExists(diagramAlreadyExists === "true");
  }, [location.search]);

  const account = accounts.find((a) => a.accountId === selectedProjectId);

  const selectedClusters: ClusterWithCloudConnect[] = selectedClusterIds
    .map((id) => clusters?.find((c) => c.id === id))
    .filter(Boolean) as ClusterWithCloudConnect[];

  const cloudDiagramsK8sFeature = availableFeatures?.find((f) => f.id === "clouddiagrams");
  const selectedFeatures: KubernetesFeature[] = cloudDiagramsK8sFeature ? [cloudDiagramsK8sFeature] : [];
  const someClusterIsHealthy = selectedClusters.some(
    (c) =>
      cloudDiagramsK8sFeature && c.features?.[cloudDiagramsK8sFeature.id]?.status === KubernetesFeatureStatus.HEALTHY
  );

  const preselectAllClusters = useCallback(() => {
    if (clusterSelectorTouched || !clusters || selectedClusterIds.length > 0) return;

    const newSelectedClusterIds = clusters.filter((c) => c.projectId === selectedProjectId).map((c) => c.id);
    if (newSelectedClusterIds.length > 0) {
      setClusterSelectorTouched(true);
      setSelectedClusterIds(newSelectedClusterIds);
    }
  }, [clusters, selectedClusterIds, selectedProjectId, clusterSelectorTouched]);

  useEffect(() => {
    preselectAllClusters();
  }, [preselectAllClusters]);

  const goBack = () => {
    history.push(`/customers/${customer.id}/cloud-diagrams`);
  };

  const createDiagram = async () => {
    if (diagramAlreadyExists) {
      goBack();
      return;
    }

    await importAWSAccountCD({
      api,
      customer,
      handleCancel: goBack,
      setIsImporting: setCloudDiagramsImportLoading,
      account,
    });
  };

  const handleSkipDiscovery = async () => {
    await createDiagram();
  };

  const handleSubmit = async () => {
    const promises: Promise<AxiosResponse<void>>[] = [];

    for (const cluster of selectedClusters) {
      if (
        cloudDiagramsK8sFeature &&
        cluster.features?.[cloudDiagramsK8sFeature.id]?.status === KubernetesFeatureStatus.HEALTHY
      ) {
        promises.push(activateCluster(api, cluster?.id));
      }
    }

    await Promise.all(promises);
    await createDiagram();
  };

  const handleSelectClusters = (ids: Cluster["id"][]) => {
    setSelectedClusterIds(ids);
    if (!clusterSelectorTouched) {
      setClusterSelectorTouched(true);
    }
  };

  const stepConfig: StepConfig[] = [
    {
      label: "Discover",
      children: (
        <DiscoverEksClustersStep
          projectId={selectedProjectId}
          onSkip={handleSkipDiscovery}
          clusterType={ClusterType.EKS}
          cloudDiagramsImportLoading={cloudDiagramsImportLoading}
          diagramAlreadyExists={diagramAlreadyExists}
        />
      ),
    },
    {
      label: "Select",
      isComplete: selectedClusterIds.length > 0,
      children: (
        <SelectClustersStep
          projectId={selectedProjectId}
          onSelect={handleSelectClusters}
          selectedClusters={selectedClusters}
          clusterType={ClusterType.EKS}
        />
      ),
    },
    {
      label: "Enable feature",
      children: (
        <EnableFeaturesStep
          clusters={selectedClusters}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
          title="Enable Cloud Diagrams feature"
        />
      ),
    },
    {
      label: "Test connection",
      children: (
        <TestConnectionStep
          clusters={selectedClusters}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
        />
      ),
      isComplete: someClusterIsHealthy,
    },
  ];

  const steps = useSteps({ stepConfig, currentStep });

  return (
    <Box
      sx={{
        pt: 4,
        pb: 5,
      }}
    >
      <Stepper
        submitButtonLabel="Done"
        steps={steps}
        loading={cloudDiagramsImportLoading}
        onCancel={goBack}
        onSubmit={handleSubmit}
        overrideStep={currentStep}
        getCurrentStep={setCurrentStep}
        footerMaxWidth={830} // 1000 - offsetwidth - horizontal padding
        maxWidth={1000}
        contentSx={{
          mt: 3,
          mb: 3,
        }}
      />
    </Box>
  );
};

export default EksCloudDiagramsClusterActivation;
