import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Autocomplete, Box, Chip, FormControl, Skeleton, TextField, Tooltip } from "@mui/material";

import { useAWSAccounts } from "../../../../Settings/AWS/hooks";
import { type AWSAccount, type AWSFeatureName, FeatureDetails } from "../../../../Settings/AWS/types";

interface AwsAccountSelectorProps {
  selectedAccountId: string | null;
  onSelect: (accountId: string) => void;
  size?: "small" | "medium";
  filterAvailableOptions?: (account: AWSAccount) => boolean;

  /**
   * Optional array of target features. If provided, the selector options will be categorized
   * based on the presence of the target features (not connected/connected).
   */
  targetFeatures?: AWSFeatureName[];
}

const AwsAccountSelector = ({
  selectedAccountId,
  onSelect,
  targetFeatures = [],
  size = "medium",
  filterAvailableOptions = () => true,
}: AwsAccountSelectorProps) => {
  const [accounts, _, accountsAreLoading] = useAWSAccounts();

  const targetFeatureNames = targetFeatures?.map((f) => FeatureDetails[f].displayName) ?? [];
  const featureDetectionModeEnabled = targetFeatures?.length > 0;

  // Filter out duplicate account IDs
  const uniqueAccounts = [...new Map(accounts.map((account) => [account.accountId, account])).values()].filter(
    filterAvailableOptions
  );

  const connectedAccounts = uniqueAccounts.filter((account) =>
    targetFeatureNames.every((f) => account.featureNames.includes(f))
  );

  const handleChange = (event: React.SyntheticEvent, value: AWSAccount | null) => {
    onSelect(value?.accountId ?? "");
  };

  const getOptionLabel = (account: AWSAccount) =>
    account.properties ? `${account.accountId} (${account.properties?.friendlyName})` : account.accountId;

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement> & { key: string }, option: AWSAccount) => (
    <li {...props} key={option.id}>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        {getOptionLabel(option)}
        {featureDetectionModeEnabled && !connectedAccounts.includes(option) && (
          <Tooltip
            title={`This account doesn't have the required features enabled (${targetFeatureNames.join(", ")}).`}
          >
            <Chip variant="status" color="warning" size="small" icon={<InfoOutlinedIcon />} label="Not connected" />
          </Tooltip>
        )}
      </Box>
    </li>
  );

  const groupBy = (option: AWSAccount) => {
    if (featureDetectionModeEnabled) {
      return connectedAccounts.map((a) => a.id).includes(option.id) ? "Connected accounts" : "Not connected accounts";
    }

    return "";
  };

  if (accountsAreLoading) {
    return <Skeleton variant="rectangular" height={56} />;
  }

  const options = uniqueAccounts;

  const sortedOptions = [...options].sort((a, b) => {
    if (connectedAccounts.includes(a) && !connectedAccounts.includes(b)) {
      return -1;
    }
    if (!connectedAccounts.includes(a) && connectedAccounts.includes(b)) {
      return 1;
    }
    return 0;
  });

  return (
    <FormControl required>
      <Autocomplete
        options={sortedOptions}
        getOptionLabel={getOptionLabel}
        value={options.find((account) => account.accountId === selectedAccountId) || null}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="AWS account" size={size} />}
        groupBy={groupBy}
        renderOption={renderOption}
      />
    </FormControl>
  );
};

export default AwsAccountSelector;
