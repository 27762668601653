import { Box, Card, CardContent, Chip, Stack, type SxProps, type Theme, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";

import CountUpCard from "../../../../../Components/CountUp/CountUpCard";
import { useDarkThemeCheck } from "../../../../../Components/hooks/useDarkThemeCheck";
import { CircularProgressWithLabel } from "../../../../../Components/ProgressWithLabel";
import CardTitle from "../Components/CardTitle";

const boxSx: SxProps<Theme> = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const cardSx: SxProps<Theme> = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
};

const commonCountupProps = {
  sx: { ...boxSx },
  cardSx: { ...cardSx },
  duration: 1,
  decimals: 2,
  text: "",
  contentStyle: { fontSize: 36 },
  spacing: 1,
  prefix: "$",
};

const Fsk8sOverviewDashboard = () => {
  const theme = useTheme();
  const isDarkMode = useDarkThemeCheck();

  return (
    <Grid container spacing={2} minHeight={172}>
      <Grid size={{ xs: 12, sm: 6, md: 4 }}>
        <CountUpCard
          {...commonCountupProps}
          childAboveContent={<CardTitle>Total spend</CardTitle>}
          number={10.82}
          childBelowText={<Chip label="Up 5% vs. last week" variant="status" color="primary" />}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 4 }}>
        <CountUpCard
          {...commonCountupProps}
          childAboveContent={<CardTitle>Pod waste</CardTitle>}
          number={4.91}
          childBelowText={<Chip label="Up 3% vs. last week" variant="status" color="error" />}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 4 }}>
        <Card
          sx={{
            ...cardSx,
            flexDirection: "column",
          }}
        >
          <CardTitle>Savings potential</CardTitle>
          <CardContent sx={{ px: 3, py: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Box>
                <CircularProgressWithLabel
                  size={72}
                  value={50}
                  backgroundColor={theme.palette.statusWarning[isDarkMode ? "dark" : "light"]}
                  color="warning"
                  typographyProps={{
                    variant: "h1",
                    fontWeight: 500,
                    color: "textPrimary",
                  }}
                />
              </Box>
              <Stack>
                <Typography variant="subtitle1">Savings potential explained</Typography>
                <Typography variant="body2" color="textSecondary">
                  This indicates how much you could save on your monthly spend with PerfectScale optimization.
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Fsk8sOverviewDashboard;
