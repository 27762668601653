import { Box, Stack } from "@mui/material";

import Fsk8sBanner from "./Banner";
import { Fsk8sHeader } from "./Header";
import { Fsk8sOverviewDashboard } from "./OverviewDashboard";
import { Fsk8sClusterTable } from "./Table";

const Fsk8sPage = () => (
  <Box mt={2}>
    <Stack spacing={2}>
      <Fsk8sHeader />
      <Stack spacing={3}>
        <Fsk8sOverviewDashboard />
        <Fsk8sBanner />
        <Fsk8sClusterTable />
      </Stack>
    </Stack>
  </Box>
);

export default Fsk8sPage;
