import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useApiContext } from "@client/src/api/context";
import { useCustomerContext } from "@client/src/Context/CustomerContext";
import { consoleErrorWithSentry } from "@client/src/utils";
import { useFullScreen } from "@client/src/utils/dialog";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { type SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// TODO: Move to ENV
import { cdAppSettings } from "../import";

interface DiagramData {
  scheme_id: string;
  statussheet_id: string;
}

interface SchemeResponse {
  scheme?: {
    [key: string]: {
      statussheet?: Array<{ ssid: string }>;
    };
  };
}

interface DashboardHeadProps {
  onSearch: (query: string) => void;
}

const NetworkDiagramButton: React.FC<{ sx?: SxProps }> = ({ sx }) => {
  const [networkDiagram, setNetworkDiagram] = useState<DiagramData | undefined>();

  const { customer } = useCustomerContext();
  const api = useApiContext();

  useEffect(() => {
    const fetchNetworkDiagram = async () => {
      if (!customer?.id) {
        consoleErrorWithSentry("Missing customer");
        return;
      }

      try {
        const { data } = await api.request<SchemeResponse>({
          method: "post",
          baseURL: cdAppSettings.baseUrl,
          url: "/cloud-diagrams/api/scheme/get",
          params: {
            customer: customer.id,
            "type[]": "network",
          },
        });

        const scheme_id = Object.keys(data?.scheme || {})[0];
        const statussheet_id = data?.scheme?.[scheme_id]?.statussheet?.[0]?.ssid;

        if (scheme_id && statussheet_id) {
          setNetworkDiagram({ scheme_id, statussheet_id });
        }
      } catch (err) {
        consoleErrorWithSentry(err);
      }
    };

    fetchNetworkDiagram();
  }, [api, customer]);

  return (
    <Button
      component="a"
      variant="outlined"
      color="primary"
      sx={{ height: 40, ...sx }}
      disabled={!networkDiagram}
      href={
        networkDiagram
          ? `/cloud-diagrams/scheme/${networkDiagram.scheme_id}/${networkDiagram.statussheet_id}/${customer?.id}`
          : "#"
      }
      size="large"
    >
      View network layer
    </Button>
  );
};

let debounceTimer: NodeJS.Timeout;

const DashboardHead: React.FC<DashboardHeadProps> = ({ onSearch }) => {
  const { customer } = useCustomerContext();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { isMobile } = useFullScreen();

  useEffect(() => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      onSearch(searchQuery.trim().toLowerCase());
    }, 300);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [onSearch, searchQuery]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid sx={{ paddingTop: "1rem" }}>
        <Typography
          data-cy="clouddiagrams-title"
          variant="h1"
          sx={{
            mb: 1,
          }}
        >
          Cloud diagrams
        </Typography>
        <Typography
          data-cy="clouddiagrams-description"
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: 400,
            mb: 2,
          }}
        >
          Visualize and analyze your cloud infrastructure
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: isMobile ? "flex-start" : "center",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 1 : 0,
        }}
      >
        <TextField
          data-cy="clouddiagrams-dashboard-search"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="Search diagrams"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            },
          }}
          variant="outlined"
        />
        <NetworkDiagramButton sx={{ marginLeft: isMobile ? 0 : "0.5rem" }} />
        {!isMobile && (
          <Button
            data-cy="clouddiagrams-dashboard-creatediagram"
            variant="contained"
            color="primary"
            sx={{ marginLeft: "0.5rem" }}
            component={Link}
            to={`/customers/${customer.id}/cloud-diagrams/onboarding`}
            size="large"
          >
            Create diagram
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardHead;
