import { Link } from "react-router-dom";
import AWSLogo from "@client/src/assets/aws-logo.svg";
import AzureLogo from "@client/src/assets/azure-logo.svg";
import CloudDiagramsPreview from "@client/src/assets/clouddiagrams/dashboard-preview.jpg";
import GCPLogo from "@client/src/assets/gcp-logo.svg";
import { FAQAccordion } from "@client/src/Components/FAQ/FAQAccordion";
import { useCustomerContext } from "@client/src/Context/CustomerContext";
import { useFullScreen } from "@client/src/utils/dialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const faqs = [
  {
    question: "What is Cloud Diagrams?",
    answer:
      "DoiT Cloud Diagrams is a powerful and versatile tool that generates diagrams of your AWS cloud infrastructure. When connecting your AWS accounts, Cloud Diagrams creates one diagram for every AWS account, allowing you to visualize and analyze your resources and understand the relationships between them.",
  },
  {
    question: "How regularly will the diagrams be refreshed?",
    answer:
      "Cloud Diagrams automatically updates every 4 hours. You can also manually refresh the data at any time by selecting Refresh.",
  },
  {
    question: "What permissions are required to start using Cloud Diagrams?",
    answer: (
      <Typography variant="body2">
        Cloud Diagrams utilizes AWS-managed SecurityAudit policy and a few extra permissions in order to read
        infrastructure metadata. Please, check <a href="https://help.doit.com/docs/governance/cloud-diagrams">here</a>{" "}
        for more details.
      </Typography>
    ),
  },
  {
    question: "Which cloud service providers are currently supported in Cloud Diagrams?",
    answer:
      "Cloud Diagrams currently supports architecture visualizations for AWS. Google Cloud Platform and Microsoft Azure coming soon.",
  },
  {
    question: "What level of details can I view in a Cloud Diagram?",
    answer:
      "Cloud Diagrams provides an architecture level visualization at a service/resource level, network level view as well as detailed resource property data.",
  },
  {
    question: "What insights can I expect to gain?",
    answer:
      "Cloud Diagrams maintains an up to date visualization of your entire infrastructure without the need to switch between regions and services in AWS console. When troubleshooting or assessing a service architecture, it saves a lot of time and avoids costly mistakes by learning of dependencies and connections to other services and accounts.",
  },
];

const DefaultPage = () => {
  const { customer } = useCustomerContext();
  const { isMobile } = useFullScreen();
  return (
    <Box>
      <Container maxWidth="lg" sx={{ pb: 8, mt: 4 }}>
        <Grid
          container
          spacing={12}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid size={{ sm: 12, md: 6 }}>
            <Box>
              <Typography variant="h1" sx={{ fontSize: "36px", lineHeight: "44px", mb: "6px" }}>
                Gain near real-time cloud transparency
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  icon={<Box component="img" src={AWSLogo} sx={{ width: 16, height: 16 }} />}
                  label="Available"
                  sx={{ backgroundColor: "general.backgroundDark" }}
                />
                <Chip
                  icon={<Box component="img" src={AzureLogo} sx={{ width: 16, height: 16 }} />}
                  label="Coming soon"
                  sx={{ backgroundColor: "general.backgroundDark" }}
                />
                <Chip
                  icon={<Box component="img" src={GCPLogo} sx={{ width: 16, height: 16 }} />}
                  label="Coming soon"
                  sx={{ backgroundColor: "general.backgroundDark" }}
                />
              </Stack>
              <Typography variant="body1" sx={{ m: "26px 0" }}>
                Cloud Diagrams provides an easy-to-understand total view into your entire cloud environment, ensuring
                both cost and security transparency.
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                {!isMobile && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/customers/${customer.id}/cloud-diagrams/onboarding`}
                  >
                    Create your first diagram
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<OpenInNewIcon />}
                  component={"a"}
                  href="https://help.doit.com/docs/governance/cloud-diagrams/create"
                >
                  Learn more
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid size={{ sm: 12, md: 6 }}>
            <Card sx={{ width: "100%", height: "auto" }}>
              <CardMedia
                component="img"
                image={CloudDiagramsPreview}
                alt="Cloud Diagrams Preview"
                sx={{
                  width: "100%",
                  height: "auto",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Stack>
        {/* <Stack sx={{ backgroundColor: "general.backgroundDark" }}> */}
        <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
          <Stack spacing={2}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "500",
              }}
            >
              Frequently asked questions
            </Typography>
            <FAQAccordion faqs={faqs} />
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
};

export default DefaultPage;
