import { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Alert, Box, Button, Container, Divider, IconButton, List, ListItem, Stack, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import LoadingButton from "../../../Components/LoadingButton";
import SaveDialog from "../../../Components/SaveComponents/SaveDialog";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { type CloudAnalyticsHistoryState } from "../types";
import { AllocationDragAndDrop } from "./AllocationDragGroup/AllocationDragGroup";
import { createAllocation, updateAllocation } from "./db";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { formStoreManager, type Rule } from "./hooks/useAllocationFormState";
import useAllocationSaveValidation from "./hooks/useAllocationSaveValidation";
import { trackAllocationEvent } from "./utils";

type Props = {
  edit?: boolean;
};

export const Allocation = ({ edit }: Props) => {
  const history = useHistory<CloudAnalyticsHistoryState>();
  const { allocationId } = useParams<{ allocationId: string }>();
  const { isError, isSaveAs, isDuplicate } = useAllocationSaveValidation({ isEdit: edit, allocationId });
  const [warnings, setExistingWarnings] = useState<string[]>([]);
  const { customer } = useCustomerContext();
  const [saving, setSaving] = useState(false);
  const api = useApiContext();
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const successSnackbar = useSuccessSnackbar(3);
  const errorSnackbar = useErrorSnackbar(3);
  const {
    items,
    hasUnallocatedCosts,
    unallocatedCosts,
    setHasUnallocatedCosts,
    allocationName,
    clear,
    isAllocationOwner,
  } = useAllocationDndStore();
  const { trackEvent } = useSegmentTrackEvent();

  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${customer.id}/analytics/allocations`);
  }, [history, customer.id]);

  type RuleAction = "create" | "update" | "select";

  const getAction = useCallback(
    (rule: Rule & { unallocatedCosts?: string | undefined }): RuleAction => {
      if (!rule.selectedExistingRule || isDuplicate) {
        return "create";
      }

      const isRuleDirty =
        JSON.stringify({
          name: rule.selectedExistingRule.data.name,
          filters: rule.selectedExistingRule.data.filters,
          formula: rule.selectedExistingRule.data.formula,
          unallocatedCosts: rule.unallocatedCosts,
        }) ===
        JSON.stringify({
          name: rule.values.name,
          filters: rule.values.filterFields,
          formula: rule.values.formula,
          unallocatedCosts: rule.unallocatedCosts,
        });

      if (isRuleDirty && rule.values.id) {
        return "update";
      }
      if (!isRuleDirty && rule.values.id) {
        return "select";
      }
      return "create";
    },
    [isDuplicate]
  );

  const handleSave = useCallback(
    async (name?: string) => {
      setSaving(true);

      try {
        const isCreatingNew = !edit;
        const rules = items.main.map((item) => formStoreManager.getStore(item).getState());
        const baseAllocationData = {
          name: name || rules[0].values.name,
          description: name || rules[0].values.name,
          ...(hasUnallocatedCosts && { unallocatedCosts }),
          rules: rules.map((rule) => ({
            ...(!isDuplicate ? { id: rule.values.id, name: rule.values.name } : { name: name || rule.values.name }),
            action: getAction({ ...rule, ...(hasUnallocatedCosts && { unallocatedCosts }) }),
            filters: rule.values.filterFields,
            formula: rule.values.formula.trim(),
            draft: false,
          })),
        };

        if (isCreatingNew || isSaveAs) {
          const response = await createAllocation(api, customer.id, baseAllocationData);
          trackAllocationEvent({
            trackEvent,
            eventName: "Allocation Created",
            ruleCount: baseAllocationData.rules.length,
            hasUnallocatedCosts: Boolean(baseAllocationData.unallocatedCosts),
            allocationId: response.data.id,
          });
          successSnackbar(allocationTxt.SUCCESSFULLY_CREATED);
        } else {
          await updateAllocation(api, customer.id, allocationId, baseAllocationData);
          trackAllocationEvent({
            trackEvent,
            eventName: "Allocation Edited",
            ruleCount: baseAllocationData.rules.length,
            hasUnallocatedCosts: Boolean(baseAllocationData.unallocatedCosts),
            allocationId,
          });
          successSnackbar(allocationTxt.SUCCESSFULLY_UPDATED);
        }

        setOpenSaveDialog(false);
        handleClose();
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar(allocationTxt.FAILED_TO_SAVE);
        setOpenSaveDialog(false);
      } finally {
        setSaving(false);
      }
    },
    [
      edit,
      items.main,
      hasUnallocatedCosts,
      unallocatedCosts,
      isSaveAs,
      handleClose,
      isDuplicate,
      getAction,
      api,
      customer.id,
      trackEvent,
      successSnackbar,
      allocationId,
      errorSnackbar,
    ]
  );

  const getWarnings = useCallback(
    () => items.main.map((item) => formStoreManager.getStore(item).getState().editWarnings).filter(Boolean) || [],
    [items.main]
  );

  const handleSaveDialog = useCallback(async () => {
    const warnings = getWarnings();
    setExistingWarnings(warnings);
    if (items.main.length > 1 || hasUnallocatedCosts || warnings.length || isDuplicate) {
      setOpenSaveDialog(true);
    } else {
      handleSave();
    }
  }, [getWarnings, handleSave, items.main.length, hasUnallocatedCosts, isDuplicate]);

  const handleSaveAsDialog = useCallback(() => {
    setOpenSaveDialog(true);
  }, []);

  useEffect(() => {
    if (items.main.length > 1) {
      setHasUnallocatedCosts(true);
    }
  }, [items.main.length, setHasUnallocatedCosts]);

  // Clear all dnd store on unmount
  useEffect(
    () => () => {
      clear();
      formStoreManager.clear();
    },
    [clear]
  );

  const getSaveLabel = useCallback(() => {
    if (isSaveAs) {
      return allocationTxt.ALLOCATION_SAVE_AS;
    }
    if (isDuplicate) {
      return allocationTxt.ALLOCATION_DUPLICATE;
    }
    return allocationTxt.ALLOCATION_SAVE;
  }, [isDuplicate, isSaveAs]);

  const getModalText = useCallback(() => {
    if (isDuplicate) {
      return {
        title: allocationTxt.ALLOCATION_DUPLICATE_SAVE_TITLE,
        description: allocationTxt.ALLOCATION_DUPLICATE_SAVE_DESCRIPTION,
      };
    }
    return { title: allocationTxt.ALLOCATION_SAVE_TITLE, description: allocationTxt.ALLOCATION_SAVE_DESCRIPTION };
  }, [isDuplicate]);

  return (
    <Box sx={{ position: "relative" }}>
      <Container maxWidth="md" sx={{ mb: 10 }}>
        <Stack justifyContent="flex-start" alignItems="flex-start" rowGap={1}>
          <Stack direction="row" alignItems="center">
            <IconButton color="inherit" onClick={handleClose} size="large" sx={{ ml: { xs: "-15px", md: "-45px" } }}>
              <BackIcon />
            </IconButton>
            <Typography variant="h4">{allocationTxt.ALLOCATION_TITLE}</Typography>
          </Stack>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {allocationTxt.ALLOCATION_DESCRIPTION}
            </Typography>
          </Box>
          <Alert severity="info" sx={{ width: "100%", display: { xs: "flex", md: "none" } }}>
            {allocationTxt.ALLOCATION_MOBILE_INFO}
          </Alert>
          <AllocationDragAndDrop disabled={saving} />
        </Stack>
      </Container>

      <Box
        sx={{
          position: "fixed",
          display: { xs: "none", md: "block" },
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 980,
          backgroundColor: "background.paper",
        }}
      >
        <Divider />
        <Stack direction="row" alignContent="center" justifyContent="center" spacing={2} p={1}>
          <Container maxWidth="md" sx={{ justifyContent: "flex-end", display: "flex" }}>
            <Button color="primary" onClick={handleClose} disabled={saving} variant="text" size="large" sx={{ mr: 3 }}>
              {allocationTxt.ALLOCATION_CANCEL}
            </Button>
            <LoadingButton
              color="primary"
              loading={saving}
              onClick={isSaveAs ? handleSaveAsDialog : handleSaveDialog}
              variant="contained"
              mixpanelEventId="allocation.save"
              data-cy="footer-save-button"
              size="large"
              disabled={isError || !isAllocationOwner}
            >
              {getSaveLabel()}
            </LoadingButton>
          </Container>
        </Stack>
      </Box>
      <SaveDialog
        {...getModalText()}
        open={openSaveDialog}
        {...(warnings.length
          ? {
              helperContent: (
                <Alert severity="warning" sx={{ mt: 1 }}>
                  {allocationTxt.ALLOCATION_EDIT_SAVE_WARNING}
                  <List dense sx={{ listStyleType: "disc" }}>
                    {warnings.map((warning, index) => (
                      <ListItem key={index} dense sx={{ listStyleType: "disc", display: "list-item", p: 0 }}>
                        <Typography variant="body2">{warning}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              ),
              confirmButtonText: allocationTxt.ALLOCATION_SAVE_CONFIRM,
            }
          : {})}
        onClose={() => {
          setOpenSaveDialog(false);
        }}
        value={allocationName}
        onSave={handleSave}
        textFieldProps={{ label: allocationTxt.ALLOCATION_NAME_LABEL }}
      />
    </Box>
  );
};
