import { useParams } from "react-router";
import { CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useCluster } from "../../hooks";
import { UtilizationCharts } from "./Charts";
import ClusterDetailHeader from "./Header";
import { WorkloadsTable } from "./WorkloadsTable";

const ClusterDetailPage = () => {
  const params = useParams<{ clusterId: string; customerId: string }>();

  const [cluster, loading] = useCluster(params.clusterId);

  if (loading) {
    return <CircularProgress />;
  }

  if (!cluster) {
    return <Typography variant="h1">Cluster {params.clusterId} not found</Typography>;
  }

  return (
    <Stack spacing={3} mt={2}>
      <ClusterDetailHeader cluster={cluster} />
      <UtilizationCharts />
      <WorkloadsTable />
    </Stack>
  );
};

export default ClusterDetailPage;
